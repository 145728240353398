import { motion } from "framer-motion";

import Slider from "../components/Slider";

export default function Start() {

  const slides = {
    path: './assets/slider-start',
    images: ['view01.jpg', 'view02.jpg', 'view03.jpg',],
    imgNumber: 2
  }

  const content = {
    heading: 'Witaj w Fundacji św. Łazarza!',
    lead: 'JavaScript to język programowania, który został po raz pierwszy wprowadzony w 1995 roku przez Brendana Eicha w firmie Netscape Communications Corporation. Od tamtej pory język ten ewoluował i stał się jednym z najważniejszych języków webowych. Obecnie jest on używany do tworzenia interaktywnych aplikacji webowych, gier i aplikacji mobilnych.',
    body: <><p>W ciągu ostatnich kilku lat JavaScript przeszedł poważne zmiany i ulepszenia. W 2015 roku została wydana wersja ECMAScript 6, która dodała nowe funkcjonalności, takie jak nowe sposoby definiowania zmiennych, klasy i moduły. W 2017 roku pojawiła się wersja ECMAScript 2017, która wprowadziła m.in. asynchroniczne funkcje i uproszczone wyrażenia wywołania funkcji.</p>
    <p>JavaScript jest również bardzo popularny w środowiskach server-side, dzięki frameworkom takim jak Node.js. Node.js pozwala na uruchamianie kodu JavaScript poza przeglądarką i umożliwia tworzenie aplikacji backendowych.</p><p>Oprócz tego, pojawiły się również narzędzia do automatyzacji i zarządzania projektami JavaScript, takie jak npm i yarn, co ułatwia tworzenie i współdzielenie bibliotek i narzędzi.</p><p>W kontekście rozwoju aplikacji webowych, JavaScript jest niezbędnym językiem i nieprzypadkowo ciągle ewoluuje i ulepsza się, aby zaspokoić rosnące wymagania i potrzeby rynku.</p></>
  }

  const variants = {
    initial: {
      opacity: 0,
      x: 400,
      transition: { 
        duration: 0.7
      }
    },
    animate1: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7
      }
    },
    animate2: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.3
      }
    },
    animate3: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.6
      }
    },
    animate4: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.9
      }
    }
  }

  return (
    <>
      <div className="start-page">
        <Slider slides={slides} />
      </div>
      <motion.div className="start-content content-box" initial='initial' animate='animate1' variants={variants}>
        <motion.h2 initial='initial' animate='animate2' variants={variants}>{content.heading}</motion.h2>
        <motion.p initial='initial' animate='animate3' variants={variants}><strong>{content.lead}</strong></motion.p>
        <motion.div initial='initial' animate='animate4' variants={variants}>
          {content.body}
        </motion.div>
      </motion.div>
    </>
      
  )
}
