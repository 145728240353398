export default function ProjectCard({project}) {

  return (
      <div className="project-card" key={project.id}>
        <h2 className="project-title">{project.name}</h2>
        <div className="project-main">
          <div className="project-side">
            <img src={project.img} alt={project.name} />
            <ul className="project-list">
              {project.list}
            </ul>
          </div>
          {project.body}
        </div>
      </div>
  )
}
