//basic
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

//icons
import { TbHome } from 'react-icons/tb';
import { BsImage } from 'react-icons/bs';
import { HiSquare2Stack } from 'react-icons/hi2';
import { BsFillPeopleFill } from 'react-icons/bs';
import { IoCalendarNumber } from 'react-icons/io5';
import { FaMapMarkedAlt } from 'react-icons/fa';

export default function Menu({ handlerMenuOpen }) {

  const variants = {
    init: {
      scale: 0,
      opacity: 0,
      y: 400,
      transition: { 
        duration: 0.5
      }
    },
    animate: {
      scale: 1,
      opacity: 1,
      y: 0,
      transition: { 
        duration: 0.5
      }
    },
    exit: {
      scale: 0,
      opacity: 0,
      y: 400,
      transition: { 
        duration: 0.5
      }
    }
  }

  return (
    <div className='menu-background'>
      <div className='menu-switch' onClick={handlerMenuOpen}></div>
      <AnimatePresence>
        <motion.div className='menu-box' key='menu' initial='init' animate='animate' exit='exit' variants={variants}>
          <Link to='/start' onClick={handlerMenuOpen}>
            <div className='menu-item'>
              <TbHome />
              <h3>Start</h3>
            </div>
          </Link>
          <Link to='/o-fundacji' onClick={handlerMenuOpen}>
            <div className='menu-item foundation-ico'>
              <svg width="108" height="107" viewBox="0 0 108 107" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.4039 53.796L0.668945 72.122L37.321 59.707C38.483 67.106 40.3409 70.259 46.7799 73.304L34.9559 107L54.4649 89.856L73.382 107L62.1499 73.304C68.7919 70.876 70.6599 67.828 71.0169 59.707L107.669 72.122L90.5249 53.796L107.669 34.878L71.0169 47.884V32.514H62.1499L73.382 0L54.4649 17.735L34.9559 0L46.7799 32.514H37.321V47.884L0.668945 34.878L18.4039 53.796Z" fill="white"/>
              </svg>
              <h3>O fundacji</h3>
            </div>
          </Link>
          <Link to='/projekty' onClick={handlerMenuOpen}>
            <div className='menu-item'>
              <HiSquare2Stack />
              <h3>Projkety</h3>
            </div>
          </Link>
          <Link to='/rada-naukowa' onClick={handlerMenuOpen}>
            <div className='menu-item'>
              <BsFillPeopleFill />
              <h3>Rada<br/>naukowa</h3>
            </div>
          </Link>
          <Link to='/historia' onClick={handlerMenuOpen}>
            <div className='menu-item'>
              <IoCalendarNumber />
              <h3>Historia</h3>
            </div>
          </Link>
          <Link to='/galeria' onClick={handlerMenuOpen}>
            <div className='menu-item'>
              <BsImage />
              <h3>Galeria</h3>
            </div>
          </Link>
          <Link to='/mapa' onClick={handlerMenuOpen}>
            <div className='menu-item'>
              <FaMapMarkedAlt />
              <h3>Mapa</h3>
            </div>
          </Link>       
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
