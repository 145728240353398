import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';

//style
import './App.css';

//components
import BottomLinks from './components/BottomLinks';
import Copywright from './components/Copywright';
import DateTime from './components/DateTime';
import Menu from './components/Menu';
import MenuIcon from './components/MenuIcon';

//routes
import LogoAnimation from './routes/LogoAnimation';
import Start from './routes/Start';
import About from './routes/About';
import Projects from './routes/Projects';
import Council from './routes/Council';
import History from './routes/History';
import Gallery from './routes/Gallery';
import Map from './routes/Map';

function App() {

  const [ menuOpen, setMenuOpen ] = useState(false)

  const reset = useNavigate();

  const handlerMenuOpen = () => {
    setMenuOpen(prevMenuOpen => !prevMenuOpen)
  }

  const checkForInactivity = () => {

    const expireTime = localStorage.getItem('expireTime');

    if (expireTime < Date.now()) {
      reset('/');
    }

  };

  const updateExpireTime = () => {

    const expireTime = Date.now() + 180000;
    localStorage.setItem('expireTime', expireTime);

  };

  useEffect(() => {

    const interval = setInterval(() => {
      checkForInactivity();
    }, 20000);

    return () => clearInterval(interval);

  });

  useEffect(() => {

    updateExpireTime();

    window.addEventListener('click', updateExpireTime);
    window.addEventListener('keypress', updateExpireTime);
    window.addEventListener('scroll', updateExpireTime);
    window.addEventListener('mousemove', updateExpireTime);
    window.addEventListener('touchstart', updateExpireTime);
    window.addEventListener('touchmove', updateExpireTime);

    return () => {
      window.removeEventListener('click', updateExpireTime);
      window.removeEventListener('keypress', updateExpireTime);
      window.removeEventListener('scroll', updateExpireTime);
      window.removeEventListener('mousemove', updateExpireTime);
      window.removeEventListener('touchstart', updateExpireTime);
      window.removeEventListener('touchmove', updateExpireTime);
    }

  }, []);

  return (
    <div className='background-box'>
      
        <div className="App">
          <DateTime />
          <div className='main-box'>
            {menuOpen && <Menu handlerMenuOpen={handlerMenuOpen} />}
            <Routes>
              <Route path='/' element={<LogoAnimation />} />
              <Route path='/start' element={<Start />} />
              <Route path='/o-fundacji' element={<About />} />
              <Route path='/projekty' element={<Projects />} />
              <Route path='/rada-naukowa' element={<Council />} />
              <Route path='/historia' element={<History />} />
              <Route path='/galeria' element={<Gallery />} />
              <Route path='/mapa' element={<Map />} />
            </Routes>
          </div>
          <div className='bottom-bar'>
            <BottomLinks />
            <Copywright />
            <MenuIcon handlerMenuOpen={handlerMenuOpen}/>
          </div>
        </div>
      
    </div>
  );
}

export default App;
