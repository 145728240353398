//basic
import { useState } from "react";

export default function GalleryModule() {
    const [isLightbox, setIsLightbox] = useState(null);

    const images = [];
    const imgNumber = 12;

    for (let i = 1; i < imgNumber + 1; i++) {
        images.push(
            {
                src:`../assets/gallery/tmb/gal-${i}.jpg`,
                key: `b${i}`,
                id: i
            }
        )
    };

    const handleClick = (id) => {
       setIsLightbox(id)
    };

    const navigateImg = (e) => {
        if (e === 'prev') {
            isLightbox === 1 ? setIsLightbox(imgNumber) : setIsLightbox(prevIsLightbox => prevIsLightbox - 1)
        } else if (e === 'next') {
            isLightbox === imgNumber ? setIsLightbox(1) : setIsLightbox(prevIsLightbox => prevIsLightbox + 1)
        }
    }
    
   // onKeyDown={(e) => navigateImg(e)}

    return (
        <>
            <div className="gallery-grid">
                {images.map(image => 
                    <img 
                        src={image.src} 
                        alt='saintlazarus' 
                        key={image.key} 
                        id={image.id}
                        onClick={() => handleClick(image.id)}
                    />
                )}
            </div>
            {isLightbox && (
                <div className='modal-overlay flex column'>
                    
                    <img src={`./assets/gallery/gal-${isLightbox}.jpg`} alt='saintlazarus' className="lightbox-img" />
                    <div className="modal-navigation">
                    <div><h2 onClick={() => navigateImg('prev')}>&larr;</h2></div>
                    <div><h2 onClick={() => handleClick(null)}>&times;</h2></div>
                    <div><h2 onClick={() => navigateImg('next')}>&rarr;</h2></div>
                    </div>
                    
                </div>
            )
            }
        </>
    )
}
