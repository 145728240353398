import { useState } from 'react';

import { FaGlobeEurope } from 'react-icons/fa';
import { FaFacebook } from 'react-icons/fa';
import { FaInstagram } from 'react-icons/fa';

export default function BottomLinks() {

  const [ overlayOpen, setOverlayOpen ] = useState(false)
  const [ activeLink, setActiveLink ] = useState(null)

  const overlayOpenHandler = (id) => {
    if (overlayOpen) {
      activeLink === id ? setOverlayOpen(false) : setActiveLink(id)
    } else {
      setOverlayOpen(true)
      setActiveLink(id)
    }
  }

  const overlayClosed = () => {
    setOverlayOpen(false)
  }

  const links = [
    {
      id: 0,
      name: 'Strona fundacji',
      img:'./assets/QR-fundacja.svg',
      link: 'saintlazarus.pl'
    },
    {
      id: 1,
      name: 'Facebook',
      img:'./assets/QR-fundacja.svg',
      link: 'facebook.com/saintlazarus'
    },
    {
      id: 2,
      name: 'Instagram',
      img:'./assets/QR-fundacja.svg',
      link: 'instagram.com/saintlazarus'
    },
  ]


  return (
    <>
      <div className='bottom-links'>
        <div onClick={() => overlayOpenHandler(0)}><FaGlobeEurope /></div>
        <div onClick={() => overlayOpenHandler(1)}><FaFacebook /></div>
        <div onClick={() => overlayOpenHandler(2)}><FaInstagram /></div>
      </div>
      {overlayOpen && <div className='bottom-links-overlay'>
        <div className='background' onClick={() => overlayClosed()}></div>
        <h2>{links[activeLink].name}</h2>
        <img src={links[activeLink].img} alt={links[activeLink].name}/>
        <p>{links[activeLink].link}</p>
      </div>}
    </>
  )
}
