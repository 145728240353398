const council = [
  {
    id: 0,
    name: 'prof. dr hab. inż. Janusz Gajda',
    img: './assets/council/gajda.png',
    desc: <>
    <p>Profesor zwyczajny specjalista metrologii, elektrotechniki i elektroniki.</p>
    <p>Profesor Nauk Technicznych na Wydziale Elektrotechniki, Automatyki, Informatyki i Inżynierii Biomedycznej AGH w Krakowie.</p>
    <p>Jego obszarem badawczym jest metrologia elektryczna, badania symulacyjne systemów pomiarowych, przetwarzanie sygnałów, pomiarowe wyznaczanie parametrycznych i nieparametrycznych modeli obiektów identyfikacji, statystyczna analiza danych pomiarowych, pomiary biomedyczne.</p>
    
    </>
  },
  {
    id: 1,
    name: 'dr hab. Ewa Kłodzińska prof. IS-PIB',
    img: './assets/council/klodzinska.png',
    desc: <>
    <p>Specjalista chemii analitycznej i chemii instrumentalnej</p>
    <p>Stopień doktora nauk chemicznych uzyskała w roku 2007 na Wydziale Chemii Uniwersytetu Mikołaja Kopernika w Toruniu, na podstawie rozprawy doktorskiej „Oznaczanie mikroorganizmów za pomocą technik elektromigracyjnych do celów diagnostyki medycznej”.</p>
    <p>Praca została obroniona z wyróżnieniem, a zagadnienia w niej poruszane przez wiele lat prezentowane były na kilkudziesięciu konferencjach o zasięgu międzynarodowym. Została m.in. nagrodzona Nagrodą Marszałka Województwa Kujawsko-Pomorskiego oraz Nagrodą Zamojskiego na najlepszą rozprawę doktorską z zakresu chemii.</p>
    <p>Za osiągnięcia w dziedzinie szybkiej diagnostyki infekcji zakażeń bakteryjnych otrzymała wiele nagród zespołowych, jaki i indywidualnych, w tym: Stypendium na Rzecz Nauki Polskiej (dwukrotnie).</p>
    <p>W roku 2016 uzyskała stopień naukowy doktora habilitowanego nauk chemicznym na podstawie opracowania pt.: „Nowe rozwiązania metodyczne w elektroforezie kapilarnej dla potrzeb diagnostyki medycznej patogenów”.</p>
    </>
  },
  {
    id: 2,
    name: 'dr hab. n. med. Mieczysław Pasowicz',
    img: './assets/council/pasowicz.png',
    desc: <>
    <p>specjalista chorób wewnętrznych, kardiologii i układu krążenia, specjalista radiologii i obrazowania medycznego, ekspert wdrażania innowacji w obszarze zdrowia', 'h-index: 14,5 (tylko z najważniejszych publikacji)</p>
    <p>Od 2011 właściciel Instytutu Medycyny Innowacyjnej oraz funkcjonującej w ramach jego działalności Centrum Medycznego IMIcare w Krakowie oraz IMIcare Prestige Pasowicz Concept Clinic.</p>
    <p>Twórca i promotor nowego sposobu diagnostyki kardiologicznej, obejmującego współpracę kardiologów i radiologów. Wykształcenie w tej dziedzinie kilkunastu wychowanków; wypromowanie 2 doktorantów w zakresie kardiologii onkologicznej oraz kilkunastu specjalistów.</p>
    <p>Autor i współautor ponad 300 publikacji naukowych w większości z listy filadelfijskiej.', 'Zainteresowania naukowo-badawcze w dziedzinie kardiologii, radiologii i medycyny stylu życia oraz zdrowia, telemedycyny, medycyny regeneracyjnej, oddziaływania wodoru molekularnego, ozonu i czynników fizycznych na zdrowie i procesy starzenia, profilaktyki chorób cywilizacyjnych.</p>
    </>
  },
  {
    id: 3,
    name: 'dr hab. n. med. Joanna Czuwara',
    img: './assets/council/czuwara.png',
    desc: <>
    <p>specjalista dermatolog – wenerolog, specjalista dermatopatolog certyfikowany ICDP-UEMS</p>
    <p>Uzyskała doktorat z wyróżnieniem w dziedzinie twardziny układowej. Podczas stypendium podoktoranckiego w Medical University of South Carolina w USA zdobyła doświadczenie w biologii molekularnej w regulacji kolagenu w skórze w warunkach prawidłowych i patologicznych (twardzina układowa i nowotwory).</p>
    <p>Specjalista dermatolog-wenerolog. Od 2011 certyfikowany egzaminem europejskim ICDP-UEMS dermatopatolog. Od 2017 roku adiunkt w Katedrze i Klinice Dermatologicznej Warszawskiego Uniwersytetu Medycznego. W 2020 uzyskała stopień dr habilitowanego nadany przez Radę Dyscypliny Nauk Medycznych WUM. Od 2018 roku zasiada w Zarządzie Głównym Polskiego Towarzystwa Dermatologicznego.', 'Prowadzi pracownię histologii i diagnozuje choroby dermatologiczne, przyczyny łysienia i zmiany nowotworowe. W ramach obecnej działalności naukowej zajmuje się zjawiskami patologicznymi w chorobach zapalnych skóry, znamionach i wczesną dermatoskopową diagnostyką czerniaka.</p>
    <p>Czynnie uczestniczy w konferencjach dermatopatologicznych Amerykańskiego (ASDP) i Międzynarodowego Towarzystwa Dermatopatologicznego (IDS). Dokształca się w ośrodkach w Bostonie, Hamburgu, Graz, Londynie i Charleston. Jest wykładowcą i trenerem szkoleń z dziedziny dermatologii estetycznej SLDE, Fundacji Anti-Aging, Fenice, wykładowcą podczas międzynarodowych konferencji związanych z tematyką zabiegów estetycznych.</p>
    </>
  },
  {
    id: 4,
    name: 'dr hab. inż Ryszard Sroka',
    img: './assets/council/sroka.png',
    desc: <>
    <p>prof. n. AGH, specjalista metrologii elektrycznej i elektronicznej, specjalista agregacji i integracji danych</p>
    <p>Ukończył studia na Wydziale Elektrotechniki Automatyki i Elektroniki AGH w 1989 r. Stopień doktora uzyskał w 1995 r., a doktora habilitowanego w 2009 r. w dyscyplinie elektrotechnika w zakresie pomiarów elektrycznych.</p>
    <p>Autor i współautor 9 podręczników i monografii oraz ponad 140 artykułów naukowych. Jest członkiem towarzystw naukowych: - Society of Weigh-in-Motion, - Society of ITS Poland and IEEE Solid-State Circuits Society.</p>
    <p>Obszar zainteresowań badawczych to: szeroko pojęta Elektrotechnika, a w szczególności metrologia elektryczna i elektroniczna, projektowanie, konstrukcja i zastosowania czujników wielkości nieelektrycznych, przetwarzanie danych, fuzja (agregacja, integracja) danych, metody i algorytmy klasyfikacji, pomiary w ruchu, metody identyfikacji obiektów technicznych i biomedycznych. Obecnie zatrudniony jest w Katedrze Metrologii i Elektroniki na stanowisku profesora nadzwyczajnego, a od 2016 roku pełni funkcję dziekana Wydziału Elektrotechniki, Automatyki, Informatyki i Inżynierii Biomedycznej AGH w Krakowie.</p>
    <p>Kierownik 3 projektów badawczych i wykonawca w 8 projektach.</p>
    </>
  },
  {
    id: 5,
    name: 'dr hab. inż. Andrzej Skalski',
    img: './assets/council/skalski.png',
    desc: <>
    <p>specjalista biocybernetyki i inżynierii biomedycznej, specjalista przetwarzania, analizy i modelowania obrazów biomedycznych</p>
    <p>Pracownik naukowy Wydziału Elektrotechniki, Automatyki, Informatyki i Inżynierii Biomedycznej AGH w Krakowie. Jako członek zespołu naukowego kierował lub brał udział w strategicznych projektach z obszaru Inżynierii Biomedycznej.</p>
    <p>Opracował program komputerowy i skomercjalizował licencję: „algorytm segmentacji struktur kostnych w danych tomograficznych”, 2014.Jest autorem i współautorem ważnych publikacji naukowych związanych prowadzoną działalnością badawczą</p>
    </>
  },
  {
    id: 6,
    name: 'dr hab. Aleksandra Radtke',
    img: './assets/council/radtke.png',
    desc: <>
    <p>Specjalista biomateriałów medycznych, specjalista nanotechnologii medycznej</p>
    <p>Jest pracownikiem Katedry Chemii Nieorganicznej i Koordynacyjnej Wydziału Chemii Uniwersytetu Mikołaja Kopernika w Toruniu.</p>
    <p>Jej tematyka naukowa obejmuje prace badawcze w zakresie nowoczesnych biomateriałów, w tym interdyscyplinarne badania nad bioaktywnymi i fotokatalitycznie aktywnymi nanopowłokami bazującymi na ditlenku tytanu, wytwarzanymi na powierzchniach podłoży z tytanu i jego stopów, pod kątem ich zastosowania w implantologii kostnej oraz układami biodegradowalnymi na bazie żelaza na potrzeby nowoczesnej kardiologii i ortopedii.</p>
    <p>Poza pracą stricte naukową, interesuje się szeroko pojętą komercjalizacją wiedzy i technologii oraz aspektami wdrożeniowymi związanymi z wprowadzaniem wyrobów medycznych na rynek.</p>
    <p>W 2015 roku założyła wraz z grupą specjalistów z zakresu chemii, medycyny i inżynierii technologii przyrostowych spółkę akademicką typu spin-off Nano-implant, w której prowadzone są prace badawczo-rozwojowe nad innowacyjnymi powłokami dla potrzeb nowoczesnej implantologii, finansowane z funduszy regionalnych.</p>
    <p>Spółka ta uzyskała szereg wyróżnień, m.in. Lider Innowacji Pomorza i Kujaw 2017, Złota Kareta Dziennika Nowości w kategorii Nauka i Edukacja, Innowacyjna Firma Roku 2017, a opracowane przez nią powłoki uzyskały Certyfikat InnoMaRe Made in Kujawsko-Pomorskie. Działalność w zakresie komercjalizacji wyników badań.</p>
    <p>Aleksandra Radtke została wyróżniona Nagrodą Zespołową Ministra Nauki i Szkolnictwa Wyższego w kategorii wdrożeniowej, przyznaną podczas I Gali Nauki Polskiej w 2020 roku. W ramach prac na biomateriałami i nowoczesnymi nanomateriałami na potrzeby biomedyczne Aleksandra Radtke uczestniczyła i dalej uczestniczy w projektach europejskich.</p>
    </>
  },
  {
    id: 7,
    name: 'dr inż. Michał Daszczyszak',
    img: './assets/council/daszczyszak.png',
    desc: <>
    <p>specjalista elektrotechniki i eletroenergetyki, wychowawca, edukator, społecznik, wolontariusz</p>
    <p>Adiunkt w Katedrze Elektrotechniki i Elektroenergetyki Wydziału Elektrotechniki, Automatyki, Informatyki i Inżynierii Biomedycznej AGH w Krakowie. Ukończył także kierunek zarządzania na Cracow School of Business – Cracow University of Economics.</p>
    <p>Założyciel i wieloletni Prezes Zarządu Stowarzyszenia Małopolskiego Centrum Edukacji „MEC”, gdzie zajmuje się edukacją historyczną, patriotyczną i obywatelską młodzieży, a także działalnością wydawniczą. Od kilku lat jest członkiem Rady Nadzorczej Solne Miasto, gdzie wydał 5 publikacji</p>
    <p>Jest członkiem zarządu Unii Polskich Ugrupowań Monarchistycznych, a także członkiem Stowarzyszenia Szlachty Wielkopolskiej, Stowarzyszenia Potomków Sejmu Wielkiego, Polskiego Towarzystwa Heraldycznego. Pełni funkcję przeora Krakowskiej Konfraterni Orderu św. Stanisława oraz jest członkiem Wielkiej Kapituły Orderu Św. Stanisława, a także Wielkim Seneszalem Konfederacji Rycerskiego i Szpitalniczego Zakonu Św. Łazarza z Jerozolimy.</p>
    <p>Był członkiem Komitetu Sterującego ds. Konsultacji Społecznych przy Uniwersytecie Mikołaja Kopernika w Toruniu.</p>
    </>
  },
  {
    id: 8,
    name: 'dr n. med. Marek Konop',
    img: './assets/council/konop.png',
    desc: <>
    <p>specjalista fizjologii i patofizjologii eksperymentalnej, specjalista procesów gojenia ran</p>
    <p>Stopień doktora nauk medycznych uzyskał w 2017 roku w Instytucie Medycyny Doświadczalnej i Klinicznej im. M. Mossakowskiego Polskiej Akademii Nauk, na podstawie rozprawy doktorskiej pt.: „Wpływ keratynowych bioopatrunków na proces gojenia ran chirurgicznych u myszy zdrowych i z jatrogennie wywołaną cukrzycą”. Jego zainteresowania naukowe związane są z procesami gojenia ran i regeneracji tkanek z wykorzystaniem naturalnych biomateriałów na bazie keratyny jako potencjalnych bioopatrunków przyspieszających proces gojenia ran w warunkach zdrowia jak i choroby (np. u pacjentów z cukrzycą).</p>
    <p>Jest autorem i współautorem publikacji z zakresu gojenia ran opublikowanych w czasopismach z listy filadelfijskie. Wyniki badań prezentował na konferencjach o zasięgu krajowym i międzynarodowym, za które zdobył liczne wyróżnienia.Jest Członkiem Polskiego Towarzystwa Dermatologicznego.</p>
    <p>Zawodowo jest związany z Zakładem Fizjologii i Patofizjologii Eksperymentalnej Warszawskiego Uniwersytetu Medycznego.</p>
    </>
  },
  {
    id: 9,
    name: 'dr n. med. Rafał Brzeziński',
    img: './assets/council/brzezinski.png',
    desc: <>
    <p>specjalista otorynolaryngolog, chirurg szyi i głowy, specjalista medycyny estetycznej</p>
    <p>Szkolenie specjalizacyjne oraz prace doktorska odbywał w Samodzielnym Publicznym Centralnym Szpitalu Klinicznym Warszawskiego Uniwersytetu Medycznego w Warszawie ul. Banacha 1a, na oddziale otolaryngologicznym, pod opieką prof. Kazimierza Niemczyka, biorąc udział w najbardziej zaawansowanych zabiegach z dziedziny otorynolaryngologii w Polsce.</p>
    <p>Przewód oraz obrona pracy doktorskiej z zakresu otorynolaryngologii na I Wydziale Lekarskim WUM. Doświadczenie dydaktyczne zdobywał wykładając anatomie prawidłową człowieka, oraz otorynolaryngologię na Warszawskim Uniwersytecie Medycznym. Doświadczenie lekarskie zdobywał w takich instytucjach jak Wojskowy Instytut Medyczny.</p>
    <p>Wieloletni członek Senatu Warszawskiego Uniwersytetu Medycznego, oraz Rady I Wydziału Lekarskiego WUM, jak także Przewodniczący Samorządu Doktoranckiego wielu kadencji. Uczestnik licznych zjazdów naukowych i szkoleń medycznych. Autor wielu doniesień kongresowych oraz publikacji naukowych. Jego szczególne zainteresowania zawodowe, to chirurgia głowy, szyi i twarzy oraz onkologia głowy i szyi.</p>
    <p>Specjalizuje się w leczeniu zaburzeń słuchu, szumów usznych, zawrotów głowy, chorób nosa i zatok przynosowych, gardła, krtani, zaburzeń głosu, mowy, chrypki, leczeniu guzów i nowotworów gardła, szyi, jamy ustnej, zaburzeń połykania, zaburzeń w obrębie powiek i skóry twarzy, zabiegach estetycznych w obrębie głowy i szyi, zaburzeń związanych z efektami starzenia się, wiotkości skóry. Zaburzeń oddychania podczas snu i chrapania.</p>
    <p>Do wykonywanych przez Dr Rafała Brzezińskiego zabiegów należą: usuwanie ciał obcych w obrębie uszu nosa i gardła, operacje małżowin usznych, przewodu słuchowego zewnętrznego, usuwanie znamion, leczenie zmarszczek, wiotkości skóry, chorób w obrębie powiek, wiotkości powiek, i skóry twarzy, mezoterapia igłowa twarzy i szyi, wolumetria i modelowanie twarzy i szyi, botox, chirurgiczne leczenie nowotworów głowy i szyi, skóry twarzy, nosa, gardła. operacje nosa i zatok przynosowych, przywracanie drożności nosa i prawidłowego toru oddychania górnych dróg oddechowych. Septoplastyka i korekty przegrody nosa, operacje plastyczne nosa zewnętrznego, słupka nosa, koniuszka nosa, małżowin nosowych. Operacje w obrębie jamy ustnej i gardła, tonsilectomie i adenotomię czyli usuwanie migdałków podniebiennych i migdałka gardłowego, nowotworów tej okolicy. Operacje krtani i fałdów głosowych, leczenie chrypki, obrzęków Reinkego, polipów krtani i gardła dolnego. Operacje podniebienia miękkiego w leczeniu chrapania i zaburzeń snu.</p>
    <p>W 2002 otrzymał Nagrodę Okręgowej Izby Lekarskiej w Krakowie za prace naukową pt. „Czas do rozpoznania w nowotworach krwi – wskaźnik sprawności systemu służby zdrowia?”</p>
    <p>Jest także członkiem Towarzystwa Otolaryngologów Chirurgów Głowy i Szyi. Prywatnie humanista, autor wielu dzieł literackich i muzycznych. Zaangażowany w pracę społeczno-patriotyczną.</p>
    </>
  },
  {
    id: 10,
    name: 'dr n. med. Izabela Załęska',
    img: './assets/council/zaleska.png',
    desc: <>
    <p>ekspert międzynarodowy kosmetologii i kosmetologii medycznej</p>
    <p>Absolwentka Uniwersytetu Medycznego w Łodzi. Tytuł doktora uzyskała na wydziale lekarskim Uniwersytetu Jagiellońskiego. Wieloletni praktyk, trener, a także nauczyciel akademicki. Obecnie wykładowca PPUZ w Nowym Targu, a także Wyższej Szkoły Planowania Strategicznego w Dąbrowie Górniczej, gdzie prowadzi zajęcia na studiach podyplomowych w dziedzinie estetyki. Współtwórca linii kosmeceutyków dla jednej z marek brytyjskich.</p>
    <p>Członek międzynarodowych organizacji: - Society of Cosmetic Scientists, - British Medical Laser Association, - European Laser Association, - American Institute of Ultrasound in Medicine, - The Aesthetic Multispecialty Society.</p>
    <p>Autorka ponad 40 publikacji naukowych oraz artykułów popularnonaukowych i eksperckich związanych z jej specjalizacją zawodową. Prelegent licznych konferencji min. w Chinach, Francji, Hiszpanii, Irlandii, Japonii, Malezji, Wielkiej Brytanii, czy Zjednoczonych Emiratach Arabskich. Od 2019 pełni także rolę Redaktora Naczelnego w czasopiśmie Aesthetic Business.</p>
    <p>Od 2022 jest Prezesem International Association of Mesotherapy IAM. Dr Izabela Załęska to również specjalista na co dzień pracujący w Klinice Młodości oraz Podgórskim Domu Urody w Krakowie oraz klinikach medycznych w Londynie.</p>
    </>
  },
  {
    id: 11,
    name: 'dr n. farm Wioleta Jankowiak',
    img: './assets/council/jankowiak.png',
    desc: <>
    <p>specjalista medycyny estetycznej, ekspert kosmetologii medycznej i przeciwstarzeniowej</p>
    <p>Adiunkt, kierownik kierunku Kosmetologia w Instytucie Nauk o Zdrowiu Akademii Pomorskiej w Słupsku. Wieloletni wykładowca, praktyk, organizator wielu szkoleń z zakresu kosmetologii medycznej.</p>
    <p>Państwowy Egzaminator w zawodzie Technik Usług Kosmetycznych. Biegły sądowy z zakresu kosmetologii leczniczej. Pracownik naukowo-badawczy, autorka wielu publikacji naukowych w czasopismach krajowych oraz zagranicznych, takich jak „Our Dermatology”, „Medical & Clinical Research”, czy „Lek w Polsce”</p>
    <p>Konsultant naukowy międzynarodowych korporacji kosmetologicznych.Właściciel Instytutu Kosmetologii Medycznej Violmedica w Poznaniu.</p>
    </>
  },
  {
    id: 12,
    name: 'dr n. chem. inż. Monika Partyka',
    img: './assets/council/partyka.png',
    desc: <>
    <p>specjalista chemii i chemii laboratoryjnej</p>
    <p>ekspert w zakresie bezpieczeństwa żywności, farmaceutyków, suplementów diety, kosmetyków oraz materiałów opakowaniowych</p>
    <p>Jej profil naukowo-badawczy obejmuje problematykę: walidacja procesów, spektrometria masowa, chromatografia ciekła, chromatografia w fazie wstecznej, ekstrakcja fazy stałego, trawienie mikrofalowe, analiza leków, analiza farmaceutyczna, kontrola jakości chemikaliów, żywności i farmaceutyków.</p>
    <p>W roku 2006 obroniła rozprawę doktorską pt.:„Wykorzystanie techniki dozymetrii pasywnej w badaniach jakości powietrza wewnętrznego” i uzyskała stopień doktora nauk chemicznych, nadany decyzją Rady Wydziału Chemicznego Politechniki Gdańskiej. </p>
    <p>Ukończyła liczne szkolenia i warsztaty związane z wykorzystaniem różnych technik analitycznych w badaniach laboratoryjnych.</p>
    <p>Specjalizację chemiczną uzupełniła w zakresie zarządzania kapitałem ludzkim, zarządzaniem projektami, co ukoronowała studiami i tytułem MBA.</p>
    </>
  },
  {
    id: 13,
    name: 'dr n. farm Wioleta Jankowiak',
    img: './assets/council/jankowiak.png',
    desc: <>
    <p>specjalista biologii, mikrobiologii, entomologii, biostatystyki</p>
    <p>specjalista w zakresie parazytologii i koewolucji stymulujących procesy gojenia ran</p>
    <p>ekspert metodologii prowadzenia badań eksperymentalnych</p>
    <p>Profil naukowy: bioróżnorodność, ekosystemy, toksykologia, kumulacja metali ciężkich, biologia i bio-fizykochemia wody, markery molekularne</p>
    <p>Do roku 2016 pracownik naukowo-dydaktyczny w Instytucie Zoologii Uniwersytetu Jagiellońskiego. Obecnie pracownik naukowy na Politechnice Śląskiej.</p>
    <p>Profesor University of Wisconsin w USA, nagrodzony tytułem VIPI.</p>
    <p>Autor 68 publikacji naukowych oraz 78 wystąpień konferencyjnych.</p>
    </>
  },
  {
    id: 14,
    name: 'dr Aleksandra Sominka',
    img: './assets/council/sominka.png',
    desc: <>
    <p>specjalista biologii molekularnej i biotechnologii</p>
    <p>specjalista marketingu kosmetycznego</p>
    <p>Obroniła rozprawę doktorską na Uniwersytecie w Poczdamie, Niemcy – Departament Biochemii i Biologii, Katedra Biologii Molekularnej przedstawiając tezę pt. „Identyfikacja i inżynieria produkcja termostabilnych ksylanaznów grzybowych przez ukierunkowaną mutagenezę w analizie sekwencyjnej i strukturalnej”.</p>
    <p>Wyniki badań dysertacji doktorskiej zaprezentowała na „13th European Workshop on Lignocellulosics and Pulp – EWLP 2014 in Seville, Spain”.</p>
    <p>W ramach studiów doktoranckich odbyła także staż naukowy w Instytucie Maxa Plancka w Poczdamie, Niemcy w sekcji biologii molekularnej roślinnej.</p>
    <p>Obroniła tytuł magistra biotechnologii na Uniwersytecie Szczecina, Katedra Biotechnologii na Wydziale Nauk Przyrodniczych.</p>
    <p>Ukończyła studia podyplomowe w Wyższej Szkole Zdrowia, Urody i Edukacji w Poznaniu z tytułem pracy „Kosmetyka z marketingiem produktu kosmetycznego”.</p>
    </>
  },
  {
    id: 15,
    name: 'dr n. o zdr., mgr piel. Ewa Guz',
    img: './assets/council/guz.png',
    desc: <>
    <p>specjalista intensywnej terapii i pielęgniarstwa anestezjologicznego</p>
    <p>ekspert w dziedzinie leczenia ran</p>
    <p>ekspert w dziedzinie powikłań po zabiegach medycyny estetycznej</p>
    <p>Dyrektor Monoprofilowego Centrum Symulacji Medycznych. Specjalista w dziedzinie Pielęgniarstwa Anestezjologicznego. Pracownik naukowy. </p>
    <p>Jest absolwentką Uniwersytetu Medycznego w Lublinie, gdzie na Wydziale Nauk o Zdrowiu uzyskała stopień doktora. Jest również magistrem pielęgniarstwa, specjalizującym się w intensywnej terapii i pielęgniarstwie anestezjologicznym. </p>
    <p>Ukończyła szereg szkoleń związanych z laseroterapią i kosmetologią, między innymi na Akademii Szkoleń Kosmetologii Estetycznej.</p>
    <p>Wykładowca na wydziale Nauk o Człowieku Wyższej Szkole Ekonomii i Innowacji w Lublinie, a także wykładowca na kierunku Kosmetologii na Akademii Nauk Stosowanych Wincentego Pola w Lublinie.</p>
    </>
  },
  {
    id: 16,
    name: 'dr n. społ. Joanna Klonowska',
    img: './assets/council/klonowska.png',
    desc: <>
    <p>specjalista promocji zdrowia </p>
    <p>ekspert edukacji kosmetycznej, kosmetologicznej i medycznej</p>
    <p>Doktor nauk społecznych – pracę doktorską z pogranicza estetologii kosmetologiczno-medycznej oraz edukacji zdrowotnej przygotowała i obroniła w Katedrze Biomedycznych Podstaw Rozwoju i Wychowania na Wydziale Pedagogicznym Uniwersytetu Warszawskiego w 2016 roku. </p>
    <p>Pedagog z wieloletnim doświadczeniem – absolwentka Wydziału Pedagogicznego w Wyższej Szkole im. Pawła Włodkowica w Płocku o specjalności promocja zdrowia, edukacja zdrowotna i profilaktyka uzależnień a także andragogika.</p>
    <p>Kosmetolog – absolwentka Wydziału Farmaceutycznego Akademii Medycznej w Łodzi o specjalności kosmetologia.</p>
    <p>Menager placówek opieki zdrowotnej – absolwentka studiów podyplomowych na Wydziale Zarządzania w Wyższej Szkole im. Pawła Włodkowica w Płocku.</p>
    <p>Ratownik medyczny – absolwentka Medycznej Szkoły Zawodowej w Płocku, gdzie wiedzę teoretyczną na kierunku Ratownictwo Medyczne uzupełniała na rocznym stażu na oddziałach szpitalnych, w stacjach pogotowia ratunkowego oraz jednostkach ratownictwa gaśniczego Państwowej Straży Pożarnej. </p>
    </>
  },
  {
    id: 17,
    name: 'lek. med. Jarosław Skórski',
    img: './assets/council/skorski.png',
    desc: <>
    <p>specjalista drugiego stopnia chirurgii ogólnej</p>
    <p>Absolwent Akademii Medycznej w Warszawie.</p>
    <p>Swoje wieloletnie doświadczenie zdobywał w oddziałach szpitalnych oraz poradniach chirurgicznych.</p>
    <p>Ukończył liczne kursy dzięki i staże, dzięki którym jest certyfikowanym lekarzem medycyny estetycznej.</p>
    <p>Specjalizuje się zarówno w chirurgii ogólnej jak i medycynie estetycznej.</p>
    <p>Jest właścicielem kliniki chirurgii i medycyny estetycznej „Klinika Skórski” w Warszawie.</p>
    </>
  },
  {
    id: 18,
    name: 'Dr Eng. Yaroslav Zrobok',
    img: './assets/council/zrobok.png',
    desc: <>
    <p>physics specialist</p>
    <p>Research instrumentation expert</p>
    <p>Mechanical engineering at State University Lviv Polytechnic.</p>
    <p>Doctorate in physical sciences at State University Lviv Polytechnic.</p>
    <p>Director at State Enterprise Reserch Institute of Cathode-Ray Tubes, Ukraine.</p>
    <p>Head of Board LLC Zachidvtormet, Ukraine.</p>
    <p>Head of Farmstead Opium, Ukraine.</p>
    </>
  }
]

const about = [
  {
    id: 0,
    content: <div className="about-desc">
    <p><strong>W poszukiwaniu doskonałości …</strong></p> 
    <p><strong>Poprzez tworzenie rzeczy dających radość i satysfakcję,</strong></p>
    <p><strong>Poprzez tworzenie rzeczy żywych i istotnych dla wartości takich jak zdrowie i uroda,</strong></p> 
    <p><strong>Starając się wydobyć piękno będące cechą każdej istoty,</strong></p>
    <p><strong>Używając wiedzy najwyższego poziomu,</strong></p> 
    <p><strong>W zgodzie z etyką, stale poszukując lepszych rozwiązań,</strong></p> 
    <p><strong>Dla siebie, dla innych, dla wszystkich…</strong></p>
    </div>
  },
  {
    id: 1,
    content: <div className="about-desc">
    <p> <strong>Fundacja Św. Łazarza</strong>, polska fundacja bio-tech, utworzona w 2015 roku przez fundatora <strong>Wojciecha Karwowskiego</strong> oraz grupę naukowców, jako startup w celu realizacji projektów naukowo badawczych na rynku life-science. Fundacja Św. Łazarza skupia się na tworzeniu nowych rozwiązań z obszaru inżynierii tkankowej – wyrobów medycznych w formie hydrożeli, emulsji, koloidów, bio-implantów, opatrunków biologicznie aktywnych. Strategicznym celem jest tworzenie preparatów nowej generacji stosowanych w terapii różnego typu stanów zapalnych, stanów pourazowych, trudno gojących się ran oraz problemów skórnych. Fundacja Św. Łazarza działa także na rynku inżynierii biomedycznej w zakresie tworzenia nowych algorytmów diagnostyki obrazowej.</p>
    </div>
  },
  {
    id: 2,
    content: <div className="about-desc">
      <p> Działalność badawczo-rozwojowa (B+R) Fundacji prowadzona jest (według klasyfikacji):</p>
      <p> OECD (Organisation for Economic Co-ope- ration and Development): 3. Nauki medyczne i nauki o zdrowiu. 3.4. Biotechnologia medyczna. 3.4.a. Biotechnologia związana ze zdrowiem.</p>
      <p>NABS (Nomenclature for the Analysis and. Comparison of Scientific Programmes and Budgets): 4. Ochrona zdrowia i zwiększenie zdrowotności. 4.3. Inżynieria biomedyczna i leki.</p>
      <p>KIS (Krajowa Inteligentna Specjalizacja): 3. Wytwarzanie produktów leczniczych, a w szczególności drugi jej obszar tematyczny „Innowacyjne produkty generyczne oraz innowacyjne wyroby medyczne, suplementy diety, środki spożywcze specjalnego przeznaczenia żywieniowego” i dalej „Wytwarzanie innowacyjnych produktów przy wykorzystaniu znanych substancji czynnych”.</p>
      <p>Aktualne obszary badań, to:</p>
      <ol>
        <li>1. Bioelektryczny transporter transdermalny do podawania substancji aktywnych w głąb tkanki.</li>
        <li>2. Modulacja komunikacji międzykomórkowej fazy zapalnej i gojenia w uszkodzeniach tkanki.</li>
        <li>3. Zastosowanie tetrapeptydów do wzbogacania opatrunków hydrożelowych z długim czasem uwalniania.</li>
        <li>4. Sterowanie reakcją komórka-implant w implantacjach precyzyjnych i depozytach tkankowych w chirurgii rekonstrukcyjnej i medycynie estetycznej.</li>
        <li>5. Modyfikacja energii powierzchniowej implantów biomateriałami o właściwościach kwantowo-elektrycznych. </li>
        <li>6. Obrazowanie termograficzne hybrydowe zmian tkankowych, naczyniowych i neuropatycznych kończyn dolnych.</li>
        <li>7. Oddziaływania fotonowe, radiowe i akustyczne na mechanobiologię blizn pourazowych tkanek skóry.</li>
      </ol>
    </div>
  },
  {
    id: 3,
    content: <div className="about-desc">
      <strong>Wojciech Karwowski</strong> – Prezes Zarządu
      <p>Menedżer, ekonomista, bioinformatyk, specjalista inżynierii biomedycznej i inżynierii tkankowej. </p>
      <p>Ukończył dwa fakultety krajowe – z Informatyki w obszarze algorytmów genetycznych, sztucznej inteligencji i sieci neuronowych oraz Ekonomii w temacie reorganizacji rozproszonych sieci przetwarzania informacji. Jest alumnem IESE Business School – University of Navarra, jednej z najbardziej prestiżowych szkół biznesu na świecie, gdzie ukończył specjalistyczny program z zarządzania strategicznego. </p>
      <p>Pracując w Stanach Zjednoczonych w „krzemowej dolinie” dla gigantów technologii multimedialnych i łączności bezprzewodowej doktoryzował się na prywatnym fakultecie z Zarządzania Procesami w Phoenix, jednak po powrocie do kraju nigdy się nie nostryfikował.</p>
      <p>Odchodzi jednak z kierunku optymalizacji procesów w stronę modelowania w biomedycynie i biomateriałach medycznych. Stąd jest także absolwentem studiów podyplomowych z Biochemii, Biofizyki i Biotechnologii, także z Biomateriałów Medycznych oraz słuchaczem kolejnych z Biologii Molekularnej, a doktoryzuje się na kierunku Biocybernetyki i Inżynierii Biomedycznej. </p>
    </div>
  }
]

const projects = [
  {
    id: 0,
    name: 'H2020 - Project number: 858247 „Tagged (DIVA) polyantigenic vaccine against Bovine Viral Diarrhea virus (BVDv) based on virus-Like-Particle)”; BoVLP-BVD.”',
    img: './assets/projects/pr01.png',
    body: <div className="project-body">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
    </div>,
    list: <>
    <li>Najważniejsze fakty</li>
    <li>Kolejny punkt</li>
    <li>I jeszcze jeden</li>
    </>
  },
  {
    id: 1,
    name: 'POIR.01.01.01-00-1216/17 „Opracowanie innowacyjnego wyrobu medycznego do zapobiegania wystąpienia zgorzeli cukrzycowej w przypadku urazów aparatu ruchu.”',
    img: './assets/projects/pr02.png',
    body: <div className="project-body">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
    </div>,
    list: <>
    <li>Najważniejsze fakty</li>
    <li>Kolejny punkt</li>
    <li>I jeszcze jeden</li>
    </>
  },
  {
    id: 2,
    name: 'NCN Opus 19, 2020/37/B/ST4/02136 „A new approach in identifying microorganisms with particular regard to viruses.”',
    img: './assets/projects/pr03.png',
    body: <div className="project-body">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident
    </div>,
    list: <>
    <li>Najważniejsze fakty</li>
    <li>Kolejny punkt</li>
    <li>I jeszcze jeden</li>
    </>
  }
]

const history = [
  {
    date: '2016',
    heading: 'Jakieś ważne wydarzenie',
    desc: 'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident'
  },
  {
    date: '2017',
    heading: 'Ważne wydarzenie',
    desc: 'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident'
  },
  {
    date: '2018',
    heading: 'Inne wydarzenie',
    desc: 'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident'
  },
  {
    date: '2019',
    heading: 'Coś znów się stało',
    desc: 'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident'
  },
  {
    date: '2020',
    heading: 'A teraz coś innego',
    desc: 'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident'
  },
  {
    date: '2021',
    heading: 'Jakieś ważne wydarzenie',
    desc: 'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident'
  },
  {
    date: '2022',
    heading: 'Ot historia powoli się kończy',
    desc: 'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident'
  },
  {
    date: '2023',
    heading: 'A na koniec...',
    desc: 'aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proidentsunt in culpa qui officia deserunt mollit anim id est laborumLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident'
  }
]

export { council, about, projects, history }