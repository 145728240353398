import { motion } from "framer-motion";

export default function PersonCard({ person }) {

  const variants = {
    initial: {
      opacity: 0,
      x: 400,
      transition: { 
        duration: 0.7
      }
    },
    animate1: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7
      }
    },
    animate2: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.3
      }
    },
    animate3: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.6
      }
    },
    animate4: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.9
      }
    }
  }

  return (
    <motion.div className="content-box person-card" key={person.id} initial='initial' animate='animate1' variants={variants}> 
      <div className="person-heading">
        <motion.div className="person-img" initial='initial' animate='animate2' variants={variants}><img src={person.img} alt={person.name}/></motion.div>
        <motion.h2 className="person-name" initial='initial' animate='animate3' variants={variants}>{person.name}</motion.h2>
      </div>
      <motion.div className="person-desc" initial='initial' animate='animate4' variants={variants}>
        {person.desc}
      </motion.div>
    </motion.div>
  )
}
