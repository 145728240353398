import { useState, useEffect } from "react";

import { motion } from "framer-motion";

import MapImage from "../components/MapImage";

export default function Map() {

  const [ activeRoom, setActiveRoom ] = useState('');

  const handleRoomClick = (roomId) => {
    setActiveRoom(roomId);
  };

  const room = {
    activeId: activeRoom,
    set: handleRoomClick
  }

  useEffect(() => {
    console.log(activeRoom);
  }, [activeRoom]);

  const variants = {
    initial: {
      opacity: 0,
      x: 400,
      transition: { 
        duration: 0.7
      }
    },
    animate1: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7
      }
    },
    animate2: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.3
      }
    },
    animate3: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.6
      }
    },
    animate4: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.9
      }
    }
  }


  return (
    <div className="map">
      <div className="map-legend">
        <h1 className="map-title">Mapa obiektu</h1>
        <div className="map-legend-list content-box">
          <h2>Legenda</h2>
          <ul>
            <li className={`map-legend-list-item ${activeRoom === 'room-01' ? 'active' : ''}`}><span>1.</span> Sala warsztatowa</li>
            <li className={`map-legend-list-item ${activeRoom === 'room-02' ? 'active' : ''}`}><span>2.</span> Aula półkolista</li>
            <li className={`map-legend-list-item ${activeRoom === 'room-03' ? 'active' : ''}`}><span>3.</span> Ubikacja damska</li>
            <li className={`map-legend-list-item ${activeRoom === 'room-04' ? 'active' : ''}`}><span>4.</span> Ubikacja męska</li>
            <li className={`map-legend-list-item ${activeRoom === 'room-05' ? 'active' : ''}`}><span>5.</span> Stołówka</li>
            <li className={`map-legend-list-item ${activeRoom === 'room-06' ? 'active' : ''}`}><span>6.</span> Sala wykładowa</li>
            <li className={`map-legend-list-item ${activeRoom === 'room-07' ? 'active' : ''}`}><span>7.</span> Aula duża</li>
            <li className={`map-legend-list-item ${activeRoom === 'room-08' ? 'active' : ''}`}><span>8.</span> Sala wykładowa</li>
            <li className={`map-legend-list-item ${activeRoom === 'room-09' ? 'active' : ''}`}><span>9.</span> Sala duża</li>
          </ul>
        </div>
      </div>
      <motion.div className="map-box content-box" initial='initial' animate='animate1' variants={variants}>
        <motion.div initial='initial' animate='animate2' variants={variants}><MapImage room={ room } /></motion.div>
      </motion.div>
    </div>
  );
};
