import { useState } from "react";

import { BsArrowUpCircleFill } from 'react-icons/bs';
import { BsArrowDownCircleFill } from 'react-icons/bs';

import { council } from "../data/data";


import PersonCard from "../components/PersonCard";
import Button from "../components/Button";

export default function Council() {

  const [ activePerson, setActivPerson ] = useState(0)

  const clickHandler = (id) => {
    setActivPerson(id)
  }

  const councilList = []
  
  council.map((i) => {
    const newItem = {
      text: i.name,
      id: i.id
    }
    councilList.push(newItem)
    return (null)
  })

  return (
    <>
      <div className="council-menu">
        <h1>Rada Naukowa</h1>
        <div className="button-list">
          <div className="council-menu-offset"></div>
          <BsArrowUpCircleFill />
          {councilList.map(person => (
            <div className={activePerson === person.id ? 'council-button council-button-active' : 'council-button'} key={person.id} onClick={() => clickHandler(person.id)}><Button btnContent={person} /></div>
          ))}
          <BsArrowDownCircleFill />
        </div>
      </div>
      <PersonCard person={council[activePerson]}/>
    </>
    
  )
}
