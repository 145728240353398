import { motion } from "framer-motion";

export default function HistoryCard({ entry }) {

  const variants = {
    initial: {
      opacity: 0,
      x: 400,
      transition: { 
        duration: 0.7
      }
    },
    animate1: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7
      }
    },
    animate2: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.3
      }
    },
    animate3: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.6
      }
    },
    animate4: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.9
      }
    }
  }

  return (
    <motion.div className="content-box history-card" key={entry.date} initial='initial' animate='animate1' variants={variants}>
      <motion.h2 className="history-heading" initial='initial' animate='animate2' variants={variants}>{entry.date} - {entry.heading}</motion.h2>
      <motion.div className="history-desc" initial='initial' animate='animate3' variants={variants}>{entry.desc}</motion.div>
    </motion.div>
  )
}
