export default function MapImage({ room }) {
  return (
    <>
    <style>
        {`#${room.activeId} > g > rect.st8 {fill:#086e77;}`}
    </style>
    <svg version="1.1" id="mapa" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 612 355.1">
      <g id="holway">
        <path className="st0" d="M413.4,85.3V50.9h-58.8v34.4H0V116h434.7v125.5h78.6V85.3H413.4z"/>
        <path className="st1" d="M413.4,85.3V50.9h-58.8v34.4H0V116h434.7v125.5h78.6V85.3H413.4z M412.2,85.3c0,0.7,0.5,1.2,1.2,1.2H512v153.7
          h-76.1c0-13,0-124.2,0-124.2c0-0.7-0.5-1.2-1.2-1.2H1.2V86.6h353.4c0.7,0,1.2-0.5,1.2-1.2V52.3h56.3v33H412.2z"/>
        <g>
          <g transform="matrix(-1,0,0,1,773.5,390)">
            <polygon className="st2" points="299,-146.9 313.4,-146.9 313.4,-146.9 			"/>
          </g>
          <path className="st3" d="M474.6,243v-0.3h0.3v0.3H474.6z M459.9,256.4v0.3h-0.3v-0.3H459.9z M459.9,243h-0.3v-0.3h0.3V243z M474.9,243
            L474.9,243h-0.6l0,0H474.9z M474.9,243.1c0,7.6-6.8,13.7-15,13.7v-0.6c8,0,14.4-5.9,14.4-13h0.6V243.1z M459.9,256.7v-0.6V256.7z
            M459.6,256.4V243h0.6v13.4H459.6z M459.9,242.7h14.7v0.6h-14.7V242.7z"/>
          <rect x="474.8" y="239.4" className="st2" width="14.4" height="3.7"/>
          <path className="st3" d="M474.6,243v-0.3h-0.3v0.3H474.6z M489.4,256.4v0.3h0.3v-0.3H489.4z M489.4,243h0.3v-0.3h-0.3V243z M474.3,243
            L474.3,243h0.6l0,0H474.3z M474.3,243.1c0,7.6,6.8,13.7,15,13.7v-0.6c-8,0-14.4-5.9-14.4-13h-0.6V243.1z M489.4,256.7v-0.6V256.7z
            M489.7,256.4V243H489v13.4H489.7z M489.4,242.7h-14.7v0.6h14.7V242.7z"/>
        </g>
        <g transform="matrix(1,0,0,1,10,-0.495078)">
          <circle className="st4" cx="460" cy="180.2" r="4.9"/>
          <circle className="st4" cx="452.4" cy="175.6" r="2.8"/>
          <circle className="st4" cx="460.3" cy="171.4" r="2.8"/>
          <circle className="st4" cx="467.7" cy="175.6" r="2.8"/>
          <circle className="st4" cx="467.7" cy="184.8" r="2.8"/>
          <circle className="st4" cx="460.3" cy="189.1" r="2.8"/>
          <circle className="st4" cx="452.4" cy="184.8" r="2.8"/>
        </g>
        <g>
          <circle className="st4" cx="453.5" cy="146.3" r="4.9"/>
          <circle className="st4" cx="445.8" cy="141.7" r="2.8"/>
          <circle className="st4" cx="453.8" cy="137.4" r="2.8"/>
          <circle className="st4" cx="461.1" cy="141.7" r="2.8"/>
          <circle className="st4" cx="461.1" cy="150.9" r="2.8"/>
          <circle className="st4" cx="453.8" cy="155.2" r="2.8"/>
          <circle className="st4" cx="445.8" cy="150.9" r="2.8"/>
        </g>
        <g transform="matrix(1,0,0,1,57.8675,-8.86394)">
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,169.52,-266.79)">
            <circle className="st4" cx="327.8" cy="229.2" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,168.604,-278.605)">
            <circle className="st4" cx="339.6" cy="233.5" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,172.95,-282.256)">
            <circle className="st4" cx="342.9" cy="243.1" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,175.64,-284.425)">
            <circle className="st4" cx="337.6" cy="252.2" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,176.5,-274.717)">
            <circle className="st4" cx="326.8" cy="248.9" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,169.639,-268.96)">
            <circle className="st4" cx="323.4" cy="237.8" r="2.8"/>
          </g>
        </g>
        <g transform="matrix(1,0,0,1,-25.9822,3.94567)">
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,-79.2589,-270.402)">
            <circle className="st4" cx="435.3" cy="158.4" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,-80.1753,-282.217)">
            <circle className="st4" cx="447" cy="162.7" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,-75.8285,-285.867)">
            <circle className="st4" cx="450.3" cy="172.3" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,-73.1393,-288.037)">
            <circle className="st4" cx="445.1" cy="181.4" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,-72.2789,-278.328)">
            <circle className="st4" cx="434.3" cy="178.1" r="2.8"/>
          </g>
          <g transform="matrix(0.849898,0.526947,-0.526947,0.849898,-79.1394,-272.571)">
            <circle className="st4" cx="430.8" cy="167" r="2.8"/>
          </g>
        </g>
        <g>
          <g transform="matrix(0.723428,0.6904,-0.6904,0.723428,135.501,-25.053)">
            
              <ellipse transform="matrix(0.6904 -0.7234 0.7234 0.6904 -87.8749 67.2108)" className="st4" cx="34.6" cy="136.3" rx="2.8" ry="2.8"/>
          </g>
          <g transform="matrix(0.723428,0.6904,-0.6904,0.723428,140.037,-37.7115)">
            
              <ellipse transform="matrix(0.6904 -0.7234 0.7234 0.6904 -89.6675 77.222)" className="st4" cx="45.4" cy="143.4" rx="2.8" ry="2.8"/>
          </g>
          <g transform="matrix(0.723428,0.6904,-0.6904,0.723428,150.967,-41.0261)">
            
              <ellipse transform="matrix(0.6904 -0.7234 0.7234 0.6904 -98.3591 81.1128)" className="st4" cx="45.6" cy="155.5" rx="2.8" ry="2.8"/>
          </g>
          <g transform="matrix(0.489731,0.871874,-0.871874,0.489731,213.332,-17.9031)">
            
              <ellipse transform="matrix(0.8719 -0.4897 0.4897 0.8719 -86.2735 41.4843)" className="st4" cx="36.1" cy="185.6" rx="2.8" ry="2.8"/>
          </g>
          <g transform="matrix(0.723428,0.6904,-0.6904,0.723428,149.235,-25.5676)">
            
              <ellipse transform="matrix(0.6904 -0.7234 0.7234 0.6904 -103.9524 67.8431)" className="st4" cx="27.3" cy="155.4" rx="2.8" ry="2.8"/>
          </g>
          <g transform="matrix(0.723428,0.6904,-0.6904,0.723428,139.06,-21.9511)">
            <ellipse transform="matrix(0.6904 -0.7234 0.7234 0.6904 -95.2606 63.353)" className="st4" cx="26.4" cy="143" rx="2.8" ry="2.8"/>
          </g>
        </g>
        <g transform="matrix(1,0,0,1,0,-4.99016)">
          <circle className="st4" cx="453.5" cy="218.2" r="4.9"/>
          <circle className="st4" cx="445.8" cy="213.6" r="2.8"/>
          <circle className="st4" cx="453.8" cy="209.3" r="2.8"/>
          <circle className="st4" cx="461.1" cy="213.6" r="2.8"/>
          <circle className="st4" cx="461.1" cy="222.8" r="2.8"/>
          <circle className="st4" cx="453.8" cy="227.1" r="2.8"/>
          <circle className="st4" cx="445.8" cy="222.8" r="2.8"/>
        </g>
        <path className="st5" d="M504.1,206.7c-1.6,0-3.1,0.4-4.5,1c-1.4,0.7-2.7,1.7-3.8,3c-1.1,1.3-1.9,2.8-2.5,4.4s-0.8,3.4-0.8,5.2
          c0,1.8,0.4,3.5,1,5.1s1.6,3,2.7,4.2s2.5,2.1,4,2.7s3,0.9,4.6,0.8l-0.4-7.6c-0.7,0-1.3-0.1-1.9-0.3c-0.6-0.3-1.2-0.6-1.7-1.2
          c-0.5-0.5-0.9-1.1-1.2-1.8c-0.3-0.7-0.4-1.4-0.4-2.2c0-0.7,0.1-1.5,0.3-2.2c0.2-0.7,0.6-1.3,1.1-1.9c0.5-0.5,1-1,1.6-1.3
          s1.3-0.4,1.9-0.4L504.1,206.7L504.1,206.7z"/>
        <g>
          <circle className="st6" cx="502.5" cy="157.4" r="3.4"/>
          <path className="st7" d="M502.5,171.8l-2.9-10.1h5.8L502.5,171.8z"/>
        </g>
      </g>
      <g id="room-01" onClick={() => room.set('room-01')}>
        <g transform="matrix(0.611246,0,0,1.0146,-366.803,-2.02919)">
          <rect x="600.1" y="2" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M816,2H600.1v84H816V2z M814,3.2v81.6c-20.5,0-191.4,0-211.9,0V3.2H814L814,3.2z"/>
        </g>
        <g>
          <g transform="matrix(1,0,0,1,-1.78881,4.48186)">
            <path className="st9" d="M114.1,48V34.7l1.5,1.5h-4.4v-2.7h6.3v14.6h-3.4V48z"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,173,-152)">
            
              <rect x="160.7" y="71.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 235.8461 -87.2579)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,169,-148)">
            
              <rect x="155.1" y="71.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 231.7681 -83.3079)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,184,-141)">
            
              <rect x="156.4" y="82.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 242.5461 -72.0489)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,180,-137)">
            
              <rect x="150.8" y="82.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 238.5681 -67.9989)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,195,-130)">
            
              <rect x="152.1" y="93.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 249.2461 -56.84)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,191,-126)">
            
              <rect x="146.6" y="93.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 245.268 -52.79)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,206,-119)">
            
              <rect x="147.9" y="104.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 256.0461 -41.531)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,202,-115)">
            
              <rect x="142.3" y="104.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 252.068 -37.4811)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,217,-108)">
            
              <rect x="143.6" y="115.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 262.746 -26.3221)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,213,-104)">
            
              <rect x="138.1" y="115.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 258.768 -22.2721)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,239.5,-85.5)">
            
              <rect x="134.9" y="137.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 276.546 4.8826)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,235.5,-81.5)">
            
              <rect x="129.4" y="137.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 272.5681 8.9326)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,250.5,-74.5)">
            
              <rect x="130.7" y="148.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 283.3461 20.1916)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,246.5,-70.5)">
            
              <rect x="125.1" y="148.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 279.268 24.1415)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,261.5,-63.5)">
            
              <rect x="126.4" y="159.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 290.046 35.4005)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,257.5,-59.5)">
            
              <rect x="120.9" y="159.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 286.068 39.4505)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,272.5,-52.5)">
            
              <rect x="122.2" y="170.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 296.8461 50.7094)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,268.5,-48.5)">
            
              <rect x="116.6" y="170.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 292.768 54.6595)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,283.5,-41.5)">
            
              <rect x="117.9" y="181.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 303.546 65.9184)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,279.5,-37.5)">
            
              <rect x="112.3" y="181.7" transform="matrix(2.535182e-06 1 -1 2.535182e-06 299.568 69.9684)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,150,-129)">
            
              <rect x="137.7" y="62.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 203.9548 -73.1578)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,146,-125)">
            
              <rect x="132.1" y="62.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 199.8766 -69.2079)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,161,-118)">
            
              <rect x="133.4" y="73.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 210.6546 -57.949)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,157,-114)">
            
              <rect x="127.8" y="73.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 206.6767 -53.899)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,172,-107)">
            
              <rect x="129.1" y="84.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 217.3546 -42.74)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,168,-103)">
            
              <rect x="123.6" y="84.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 213.3767 -38.69)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,183,-96)">
            
              <rect x="124.9" y="95.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 224.1547 -27.4311)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,179,-92)">
            
              <rect x="119.3" y="95.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 220.1767 -23.3811)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,194,-85)">
            
              <rect x="120.6" y="106.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 230.8547 -12.2221)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,190,-81)">
            
              <rect x="115.1" y="106.3" transform="matrix(2.535182e-06 1 -1 2.535182e-06 226.8766 -8.1722)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,216.5,-62.5)">
            
              <rect x="111.9" y="128.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 244.6546 18.9826)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,212.5,-58.5)">
            
              <rect x="106.4" y="128.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 240.6766 23.0326)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,227.5,-51.5)">
            
              <rect x="107.7" y="139.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 251.4546 34.2915)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,223.5,-47.5)">
            
              <rect x="102.1" y="139.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 247.3766 38.2415)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,238.5,-40.5)">
            
              <rect x="103.4" y="150.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 258.1546 49.5005)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,234.5,-36.5)">
            
              <rect x="97.9" y="150.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 254.1765 53.5504)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,249.5,-29.5)">
            
              <rect x="99.2" y="161.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 264.9547 64.8095)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,245.5,-25.5)">
            
              <rect x="93.6" y="161.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 260.8766 68.7594)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,260.5,-18.5)">
            
              <rect x="94.9" y="172.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 271.6545 80.0183)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,256.5,-14.5)">
            
              <rect x="89.4" y="172.8" transform="matrix(2.535182e-06 1 -1 2.535182e-06 267.6765 84.0683)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,127,-106)">
            
              <rect x="114.7" y="53.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 172.0632 -59.0579)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,123,-102)">
            
              <rect x="109.1" y="53.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 167.9851 -55.1079)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,138,-95)">
            
              <rect x="110.4" y="64.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 178.7633 -43.849)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,134,-91)">
            
              <rect x="104.8" y="64.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 174.7851 -39.799)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,149,-84)">
            
              <rect x="106.2" y="75.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 185.4632 -28.6401)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,145,-80)">
            
              <rect x="100.6" y="75.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 181.4851 -24.59)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,160,-73)">
            
              <rect x="101.9" y="86.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 192.2632 -13.3312)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,156,-69)">
            
              <rect x="96.3" y="86.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 188.2852 -9.281)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,171,-62)">
            
              <rect x="97.6" y="97.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 198.9632 1.8778)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,167,-58)">
            
              <rect x="92.1" y="97.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 194.9852 5.928)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,193.5,-39.5)">
            
              <rect x="88.9" y="119.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 212.7632 33.0825)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,189.5,-35.5)">
            
              <rect x="83.4" y="119.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 208.7852 37.1325)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,204.5,-28.5)">
            
              <rect x="84.7" y="130.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 219.5632 48.3915)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,200.5,-24.5)">
            
              <rect x="79.1" y="130.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 215.4851 52.3416)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,215.5,-17.5)">
            
              <rect x="80.4" y="141.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 226.2632 63.6004)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,211.5,-13.5)">
            
              <rect x="74.9" y="141.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 222.2852 67.6504)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,226.5,-6.5)">
            
              <rect x="76.2" y="152.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 233.0632 78.9094)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,222.5,-2.5)">
            
              <rect x="70.6" y="152.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 228.9851 82.8593)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,237.5,4.5)">
            
              <rect x="71.9" y="163.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 239.7632 94.1184)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,233.5,8.5)">
            
              <rect x="66.4" y="163.9" transform="matrix(2.535182e-06 1 -1 2.535182e-06 235.7851 98.1683)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,104,-83)">
            
              <rect x="91.7" y="44.6" transform="matrix(2.535182e-06 1 -1 2.535182e-06 140.1717 -44.9579)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,100,-79)">
            
              <rect x="86.1" y="44.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 136.0938 -41.0079)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,115,-72)">
            
              <rect x="87.4" y="55.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 146.8718 -29.749)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,111,-68)">
            
              <rect x="81.8" y="55.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 142.8937 -25.6991)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,126,-61)">
            
              <rect x="83.2" y="66.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 153.5718 -14.54)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,122,-57)">
            
              <rect x="77.6" y="66.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 149.5937 -10.4901)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,137,-50)">
            
              <rect x="78.9" y="77.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 160.3717 0.7689)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,133,-46)">
            
              <rect x="73.3" y="77.6" transform="matrix(2.535182e-06 1 -1 2.535182e-06 156.3937 4.8189)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,148,-39)">
            
              <rect x="74.6" y="88.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 167.0717 15.9778)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,144,-35)">
            
              <rect x="69.1" y="88.5" transform="matrix(2.535182e-06 1 -1 2.535182e-06 163.0937 20.0279)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,170.5,-16.5)">
            
              <rect x="65.9" y="111" transform="matrix(2.535182e-06 1 -1 2.535182e-06 180.8718 47.1825)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,166.5,-12.5)">
            
              <rect x="60.4" y="111" transform="matrix(2.535182e-06 1 -1 2.535182e-06 176.8936 51.2325)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,181.5,-5.5)">
            
              <rect x="61.7" y="122" transform="matrix(2.535182e-06 1 -1 2.535182e-06 187.6716 62.4915)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,177.5,-1.5)">
            
              <rect x="56.1" y="122" transform="matrix(2.535182e-06 1 -1 2.535182e-06 183.5936 66.4415)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,192.5,5.5)">
            
              <rect x="57.4" y="133" transform="matrix(2.535182e-06 1 -1 2.535182e-06 194.3716 77.7005)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,188.5,9.5)">
            
              <rect x="51.9" y="133" transform="matrix(2.535182e-06 1 -1 2.535182e-06 190.3936 81.7504)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,203.5,16.5)">
            
              <rect x="53.2" y="144" transform="matrix(2.535182e-06 1 -1 2.535182e-06 201.1718 93.0093)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,199.5,20.5)">
            
              <rect x="47.6" y="144" transform="matrix(2.535182e-06 1 -1 2.535182e-06 197.0937 96.9594)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,214.5,27.5)">
            
              <rect x="48.9" y="155" transform="matrix(2.535182e-06 1 -1 2.535182e-06 207.8716 108.2184)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,210.5,31.5)">
            
              <rect x="43.4" y="155" transform="matrix(2.535182e-06 1 -1 2.535182e-06 203.8937 112.2684)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,67,-48)">
            
              <rect x="77.4" y="8.2" transform="matrix(2.535182e-06 1 -1 2.535182e-06 137.5152 -45.6761)" className="st4" width="28.4" height="75.4"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,66.9528,-39.4508)">
            
              <rect x="70.2" y="12.1" transform="matrix(2.535182e-06 1 -1 2.535182e-06 130.2067 -35.8274)" className="st4" width="25.7" height="70.2"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,66.7558,-29.332)">
            
              <rect x="61.6" y="16.6" transform="matrix(2.535182e-06 1 -1 2.535182e-06 121.5758 -24.2907)" className="st4" width="22.6" height="64.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,66.7085,-20.7827)">
            
              <rect x="54.4" y="20.4" transform="matrix(2.535182e-06 1 -1 2.535182e-06 114.1666 -14.5419)" className="st4" width="19.9" height="58.9"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,68.4849,-6.9713)">
            
              <rect x="32.1" y="39.1" transform="matrix(2.535182e-06 1 -1 2.535182e-06 84.7983 13.2787)" className="st4" width="7.3" height="19.8"/>
          </g>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,41.5808,14.265)">
            
              <rect x="28" y="8.1" transform="matrix(2.535182e-06 1 -1 2.535182e-06 63.7666 5.2038)" className="st4" width="2.5" height="52.8"/>
          </g>
        </g>
        <g className="st10">
          <rect x="117.3" y="84.2" className="st11" width="7.7" height="2.1"/>
          <path className="st12" d="M117.1,86.4v-0.3h-0.3v0.3H117.1z M125.1,94.5v0.3h0.3v-0.3H125.1z M125.1,86.4h0.3v-0.3h-0.3V86.4z
            M116.8,86.4L116.8,86.4h0.6l0,0H116.8z M116.8,86.4c0,4.6,3.7,8.4,8.3,8.4v-0.6c-4.2,0-7.7-3.5-7.7-7.8H116.8z M125.1,94.8v-0.6
            V94.8z M125.4,94.5v-8.1h-0.6v8.1H125.4z M125.1,86.1h-8v0.6h8V86.1z"/>
          <g transform="matrix(-1,0,0,1,190.5,137)" className="st13">
            <polygon className="st2" points="73.5,-50.6 81.2,-50.6 81.2,-50.6 			"/>
          </g>
          <path className="st12" d="M117.1,86.4v-0.3h0.3v0.3H117.1z M109.2,94.5v0.3h-0.3v-0.3H109.2z M109.2,86.4h-0.3v-0.3h0.3V86.4z
            M117.4,86.4L117.4,86.4h-0.6l0,0H117.4z M117.4,86.4c0,4.6-3.7,8.4-8.3,8.4v-0.6c4.2,0,7.7-3.5,7.7-7.8H117.4z M109.2,94.8v-0.6
            V94.8z M108.8,94.5v-8.1h0.6v8.1H108.8z M109.2,86.1h8v0.6h-8V86.1z"/>
        </g>
      </g>
      <g id="room-02" onClick={() => room.set('room-02')}>
        <g transform="matrix(0.609197,0,0,1.0146,-150.33,-2.02919)">
          <rect x="463.4" y="2" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M679.4,2h-216v84h215.9V2H679.4z M677.4,3.2v81.6c-20.5,0-191.4,0-211.9,0V3.2H677.4z"/>
        </g>
        <g>
          <g transform="matrix(1,0,0,1,-0.133146,4.12013)">
            <path className="st9" d="M193.3,45.7v-2.2l5.6-5.3c0.4-0.4,0.8-0.8,1-1.1s0.3-0.6,0.4-0.9c0.1-0.3,0.1-0.5,0.1-0.7
              c0-0.6-0.2-1-0.6-1.3c-0.4-0.3-1-0.5-1.7-0.5c-0.6,0-1.2,0.1-1.7,0.4c-0.5,0.2-1,0.6-1.3,1.1l-2.5-1.6c0.6-0.8,1.3-1.5,2.3-2
              s2.2-0.7,3.5-0.7c1.1,0,2,0.2,2.8,0.5s1.5,0.8,1.9,1.5c0.5,0.6,0.7,1.4,0.7,2.3c0,0.5-0.1,0.9-0.2,1.4c-0.1,0.5-0.3,0.9-0.7,1.5
              c-0.3,0.5-0.9,1.1-1.5,1.7l-4.7,4.4l-0.6-1.2h8.2v2.8L193.3,45.7L193.3,45.7z"/>
          </g>
          <rect x="169.6" y="53.1" className="st4" width="56.4" height="29"/>
          <rect x="171.5" y="55.8" className="st4" width="52.5" height="26.3"/>
          <rect x="173.8" y="59" className="st4" width="47.9" height="23.1"/>
          <rect x="175.7" y="61.7" className="st4" width="44" height="20.4"/>
          <rect x="179.3" y="65.5" className="st4" width="14.7" height="7.5"/>
          <rect x="178" y="80.5" className="st4" width="39.5" height="2.6"/>
          <g transform="matrix(0.0141838,-0.999899,0.999899,0.0141838,140.446,341.535)">
            
              <rect x="280.6" y="4.8" transform="matrix(-1 5.471694e-06 -5.471694e-06 -1 567.3742 11.4848)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.0141838,-0.999899,0.999899,0.0141838,144.332,345.589)">
            
              <rect x="284.6" y="3.3" transform="matrix(-1 5.471694e-06 -5.471694e-06 -1 575.3694 11.5564)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.331983,-0.943285,0.943285,0.331983,85.6625,285.983)">
            
              <rect x="243.5" y="-17.4" transform="matrix(-1 5.062588e-06 -5.062588e-06 -1 493.0407 -32.9497)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.331983,-0.943285,0.943285,0.331983,86.9302,290.429)">
            
              <rect x="247.2" y="-17.5" transform="matrix(-1 5.062588e-06 -5.062588e-06 -1 500.5327 -30.1935)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.629851,-0.776716,0.776716,0.629851,41.7621,220.185)">
            
              <rect x="210.4" y="-25.5" transform="matrix(-1 5.088100e-06 -5.088100e-06 -1 426.9153 -49.2503)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.629851,-0.776716,0.776716,0.629851,40.9552,223.531)">
            
              <rect x="213.5" y="-24.5" transform="matrix(-1 5.088102e-06 -5.088102e-06 -1 433.1435 -44.1276)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.819379,-0.573252,0.573252,0.819379,16.7807,160.721)">
            
              <rect x="191.9" y="-21.6" transform="matrix(-1 5.138892e-06 -5.138892e-06 -1 389.911 -41.4798)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.819379,-0.573252,0.573252,0.819379,15.3161,162.628)">
            
              <rect x="194.2" y="-19.9" transform="matrix(-1 5.138893e-06 -5.138893e-06 -1 394.4834 -34.9254)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.921232,-0.389015,0.389015,0.921232,4.32529,111.349)">
            
              <rect x="185.1" y="-12.6" transform="matrix(-1 5.061948e-06 -5.061948e-06 -1 376.3705 -23.4817)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.921232,-0.389015,0.389015,0.921232,3.01449,112.244)">
            
              <rect x="186.7" y="-10.6" transform="matrix(-1 5.061948e-06 -5.061948e-06 -1 379.5941 -16.2995)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.973814,-0.227347,0.227347,0.973814,-1.23669,67.4959)">
            
              <rect x="185.7" y="-1.2" transform="matrix(-1 5.068326e-06 -5.068326e-06 -1 377.5986 -0.6732)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.973814,-0.227347,0.227347,0.973814,-2.09849,67.8048)">
            
              <rect x="186.7" y="1" transform="matrix(-1 5.068326e-06 -5.068326e-06 -1 379.4378 6.9532)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.996983,-0.0776246,0.0776246,0.996983,-1.84131,24.1082)">
            
              <rect x="191" y="12.7" transform="matrix(-1 4.267398e-06 -4.267398e-06 -1 388.1794 27.2576)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.996983,-0.0776246,0.0776246,0.996983,-2.14996,24.1444)">
            
              <rect x="191.4" y="15.2" transform="matrix(-1 4.267398e-06 -4.267398e-06 -1 388.8228 35.2533)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.998239,0.0593198,-0.0593198,0.998239,2.6402,-19.1845)">
            
              <rect x="200.6" y="26.7" transform="matrix(5.464126e-06 1 -1 5.464126e-06 231.2531 -171.6595)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.998239,0.0593198,-0.0593198,0.998239,2.87666,-19.1634)">
            
              <rect x="198.7" y="30.7" transform="matrix(5.464126e-06 1 -1 5.464126e-06 234.9622 -167.4352)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.977406,0.211369,-0.211369,0.977406,15.3996,-71.3505)">
            
              <rect x="211.6" y="48.4" transform="matrix(5.094734e-06 1 -1 5.094734e-06 263.9334 -161.0697)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.977406,0.211369,-0.211369,0.977406,16.2067,-71.0834)">
            
              <rect x="209.2" y="52.3" transform="matrix(5.094734e-06 1 -1 5.094734e-06 266.9738 -156.3283)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.925898,0.377773,-0.377773,0.925898,41.671,-131.964)">
            
              <rect x="225.6" y="75.9" transform="matrix(5.106353e-06 1 -1 5.106353e-06 305.4172 -147.5067)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.925898,0.377773,-0.377773,0.925898,42.958,-131.119)">
            
              <rect x="222.6" y="79.6" transform="matrix(5.106353e-06 1 -1 5.106353e-06 307.6458 -142.4349)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.818128,0.575037,-0.575037,0.818128,94.9779,-205.732)">
            
              <rect x="246.5" y="113" transform="matrix(5.054402e-06 1 -1 5.054402e-06 363.4728 -131.2796)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.818128,0.575037,-0.575037,0.818128,96.4414,-203.815)">
            
              <rect x="242.6" y="116.4" transform="matrix(5.054402e-06 1 -1 5.054402e-06 364.4398 -125.6299)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.620134,0.784496,-0.784496,0.620134,192.018,-280.981)">
            
              <rect x="278.2" y="158.9" transform="matrix(5.052506e-06 1 -1 5.052506e-06 441.1028 -117.1521)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.620134,0.784496,-0.784496,0.620134,192.772,-277.577)">
            
              <rect x="273.5" y="161.5" transform="matrix(5.052506e-06 1 -1 5.052506e-06 440.4865 -111.4261)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.314607,0.949222,-0.949222,0.314607,340.58,-326.289)">
            
              <rect x="323.5" y="207.2" transform="matrix(5.041934e-06 1 -1 5.041934e-06 534.7186 -114.1719)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.314607,0.949222,-0.949222,0.314607,339.173,-321.823)">
            
              <rect x="318.1" y="208.4" transform="matrix(5.041936e-06 1 -1 5.041936e-06 532.059 -109.1295)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.00948223,0.999955,-0.999955,0.00948223,485.702,-312.223)">
            
              <rect x="366.8" y="241.4" transform="matrix(5.530490e-06 1 -1 5.530490e-06 612.1328 -123.2227)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.00948223,0.999955,-0.999955,0.00948223,481.778,-308.185)">
            
              <rect x="361.2" y="241.5" transform="matrix(5.530490e-06 1 -1 5.530490e-06 608.1306 -119.0723)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.0123514,-0.999924,0.999924,0.0123514,115.267,326.552)">
            
              <rect x="262.5" y="18.2" transform="matrix(-1 4.270088e-06 -4.270088e-06 -1 531.015 38.2943)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.0123514,-0.999924,0.999924,0.0123514,119.168,330.601)">
            
              <rect x="266.5" y="16.7" transform="matrix(-1 4.270088e-06 -4.270088e-06 -1 539.0157 38.3371)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.195689,-0.980666,0.980666,0.195689,92.6469,290.875)">
            
              <rect x="240.5" y="-2.5" transform="matrix(-1 5.077470e-06 -5.077470e-06 -1 487.0706 -3.2276)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.195689,-0.980666,0.980666,0.195689,95.0341,295.352)">
            
              <rect x="244.4" y="-3.4" transform="matrix(-1 5.077470e-06 -5.077470e-06 -1 494.963 -1.852)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.396451,-0.918056,0.918056,0.396451,70.3016,252.902)">
            
              <rect x="218.5" y="-18.9" transform="matrix(-1 5.085856e-06 -5.085856e-06 -1 443.0828 -36.0208)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.396451,-0.918056,0.918056,0.396451,71.0618,257.23)">
            
              <rect x="222.2" y="-19" transform="matrix(-1 5.085856e-06 -5.085856e-06 -1 450.5708 -33.0326)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.583911,-0.811818,0.811818,0.583911,50.3853,214.681)">
            
              <rect x="200" y="-28.5" transform="matrix(-1 5.071900e-06 -5.071900e-06 -1 406.0199 -55.242)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.583911,-0.811818,0.811818,0.583911,49.8404,218.289)">
            
              <rect x="203.2" y="-27.8" transform="matrix(-1 5.071900e-06 -5.071900e-06 -1 412.4107 -50.6546)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.733859,-0.679302,0.679302,0.733859,34.0764,178.149)">
            
              <rect x="187" y="-31.5" transform="matrix(-1 5.033318e-06 -5.033318e-06 -1 380.1206 -61.283)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.733859,-0.679302,0.679302,0.733859,32.8054,180.776)">
            
              <rect x="189.7" y="-30.2" transform="matrix(-1 5.033318e-06 -5.033318e-06 -1 385.589 -55.4982)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.84358,-0.537004,0.537004,0.84358,21.2558,143.198)">
            
              <rect x="180.1" y="-29.5" transform="matrix(-1 5.052108e-06 -5.052108e-06 -1 366.3289 -57.3)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.84358,-0.537004,0.537004,0.84358,19.78,144.879)">
            
              <rect x="182.2" y="-27.7" transform="matrix(-1 5.052108e-06 -5.052108e-06 -1 370.5203 -50.5593)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.91845,-0.395537,0.395537,0.91845,11.4771,109.15)">
            
              <rect x="178.5" y="-24" transform="matrix(-1 5.051768e-06 -5.051768e-06 -1 363.1667 -46.2838)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.91845,-0.395537,0.395537,0.91845,10.1529,110.076)">
            
              <rect x="180.1" y="-22" transform="matrix(-1 5.051768e-06 -5.051768e-06 -1 366.2955 -39.145)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.965884,-0.258976,0.258976,0.965884,4.43997,74.7818)">
            
              <rect x="181.2" y="-15.7" transform="matrix(-1 5.109852e-06 -5.109852e-06 -1 368.5836 -29.5795)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.965884,-0.258976,0.258976,0.965884,3.47481,75.1819)">
            
              <rect x="182.3" y="-13.4" transform="matrix(-1 5.109852e-06 -5.109852e-06 -1 370.7274 -21.8829)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.991189,-0.132455,0.132455,0.991189,0.430852,40.078)">
            
              <rect x="186.8" y="-5.1" transform="matrix(-1 5.081944e-06 -5.081944e-06 -1 379.6896 -8.3102)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.991189,-0.132455,0.132455,0.991189,-0.0896346,40.1831)">
            
              <rect x="187.4" y="-2.7" transform="matrix(-1 5.081944e-06 -5.081944e-06 -1 380.8093 -0.4091)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.999968,-0.00795598,0.00795598,0.999968,-0.109651,2.5256)">
            
              <rect x="194.8" y="8.3" transform="matrix(-1 5.029598e-06 -5.029598e-06 -1 395.6089 18.4596)" className="st4" width="6.1" height="1.8"/>
          </g>
          <g transform="matrix(0.999968,-0.00795598,0.00795598,0.999968,-0.141474,2.52598)">
            
              <rect x="194.7" y="10.7" transform="matrix(-1 5.029598e-06 -5.029598e-06 -1 395.5367 26.3035)" className="st4" width="6.1" height="4.9"/>
          </g>
          <g transform="matrix(0.993075,0.117482,-0.117482,0.993075,4.10419,-39.0579)">
            
              <rect x="206.8" y="22.4" transform="matrix(5.075244e-06 1 -1 5.075244e-06 233.1341 -182.231)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.993075,0.117482,-0.117482,0.993075,4.56764,-38.9752)">
            
              <rect x="204.8" y="26.4" transform="matrix(5.075244e-06 1 -1 5.075244e-06 236.7003 -177.841)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.969072,0.246777,-0.246777,0.969072,15.1061,-85.5678)">
            
              <rect x="218.7" y="42.1" transform="matrix(5.066403e-06 1 -1 5.066403e-06 264.6918 -174.4495)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.969072,0.246777,-0.246777,0.969072,16.0321,-85.2044)">
            
              <rect x="216.1" y="46" transform="matrix(5.066403e-06 1 -1 5.066403e-06 267.5662 -169.5257)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.924182,0.381951,-0.381951,0.924182,35.8184,-137.289)">
            
              <rect x="232.7" y="65.5" transform="matrix(5.060778e-06 1 -1 5.060778e-06 302.0813 -165.067)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.924182,0.381951,-0.381951,0.924182,37.1143,-136.425)">
            
              <rect x="229.7" y="69.2" transform="matrix(5.060778e-06 1 -1 5.060778e-06 304.3956 -159.8613)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.848631,0.528985,-0.528985,0.848631,72.1834,-195.975)">
            
              <rect x="250.7" y="94.2" transform="matrix(5.056253e-06 1 -1 5.056253e-06 348.8595 -154.3954)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.848631,0.528985,-0.528985,0.848631,73.6587,-194.342)">
            
              <rect x="247" y="97.6" transform="matrix(5.056253e-06 1 -1 5.056253e-06 350.1711 -148.8136)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.730458,0.682958,-0.682958,0.730458,131.557,-258.017)">
            
              <rect x="274" y="128.6" transform="matrix(5.089252e-06 1 -1 5.089252e-06 406.4839 -143.2222)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.730458,0.682958,-0.682958,0.730458,132.816,-255.364)">
            
              <rect x="269.7" y="131.6" transform="matrix(5.089252e-06 1 -1 5.089252e-06 406.7498 -137.5085)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.57435,0.81861,-0.81861,0.57435,212.035,-309.82)">
            
              <rect x="300.9" y="163.9" transform="matrix(5.055032e-06 1 -1 5.055032e-06 468.7546 -134.9083)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.57435,0.81861,-0.81861,0.57435,212.522,-306.161)">
            
              <rect x="296.1" y="166.2" transform="matrix(5.055032e-06 1 -1 5.055032e-06 467.7719 -129.3085)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.381817,0.924238,-0.924238,0.381817,313.489,-342.698)">
            
              <rect x="331.6" y="199.2" transform="matrix(5.074477e-06 1 -1 5.074477e-06 534.7281 -130.2866)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.381817,0.924238,-0.924238,0.381817,312.615,-338.337)">
            
              <rect x="326.4" y="200.7" transform="matrix(5.074477e-06 1 -1 5.074477e-06 532.6042 -125.0427)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.180117,0.983645,-0.983645,0.180117,420.745,-347.218)">
            
              <rect x="361.7" y="230.5" transform="matrix(5.054932e-06 1 -1 5.054932e-06 596.1167 -129.093)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.180117,0.983645,-0.983645,0.180117,418.229,-342.757)">
            
              <rect x="356.2" y="231.2" transform="matrix(5.054932e-06 1 -1 5.054932e-06 592.8811 -124.4743)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(0.00825706,0.999966,-0.999966,0.00825706,511.667,-327.528)">
            
              <rect x="385.3" y="254.6" transform="matrix(5.190564e-06 1 -1 5.190564e-06 643.8536 -128.6415)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(0.00825706,0.999966,-0.999966,0.00825706,507.733,-323.495)">
            
              <rect x="379.8" y="254.6" transform="matrix(5.190564e-06 1 -1 5.190564e-06 639.9414 -124.591)" className="st4" width="4.9" height="6.1"/>
          </g>
        </g>
        <g>
          <rect x="146.7" y="84.2" className="st2" width="7.7" height="2.1"/>
          <path className="st3" d="M146.6,86.4v-0.3h-0.3v0.3H146.6z M154.5,94.5v0.3h0.3v-0.3H154.5z M154.5,86.4h0.3v-0.3h-0.3V86.4z
            M146.3,86.4L146.3,86.4h0.6l0,0H146.3z M146.3,86.4c0,4.6,3.7,8.4,8.3,8.4v-0.6c-4.2,0-7.7-3.5-7.7-7.8H146.3z M154.5,94.8v-0.6
            V94.8z M154.8,94.5v-8.1h-0.6v8.1H154.8z M154.5,86.1h-8v0.6h8V86.1z"/>
          <g transform="matrix(-1,0,0,1,238.5,137)">
            <polygon className="st2" points="92.1,-50.6 99.8,-50.6 99.8,-50.6 			"/>
          </g>
          <path className="st3" d="M146.6,86.4v-0.3h0.3v0.3H146.6z M138.6,94.5v0.3h-0.3v-0.3H138.6z M138.6,86.4h-0.3v-0.3h0.3V86.4z
            M146.9,86.4L146.9,86.4h-0.6l0,0H146.9z M146.9,86.4c0,4.6-3.7,8.4-8.3,8.4v-0.6c4.2,0,7.7-3.5,7.7-7.8H146.9z M138.6,94.8v-0.6
            V94.8z M138.3,94.5v-8.1h0.6v8.1H138.3z M138.6,86.1h8v0.6h-8V86.1z"/>
        </g>
      </g>
      <g id="room-03" onClick={() => room.set('room-03')}>
        <g transform="matrix(0.211324,0,0,1.0146,302.951,-2.02919)">
          <rect x="-186.5" y="2" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M29.5,2h-215.9v84H29.5V2z M23.7,3.2v81.6h-204.3V3.2H23.7z"/>
        </g>
        <g transform="matrix(1,0,0,1,3.27361,-2.92614)">
          <path className="st9" d="M282.1,55.4c-1,0-2-0.1-3-0.4s-1.8-0.7-2.5-1.2l1.3-2.6c0.6,0.4,1.2,0.7,1.9,1c0.7,0.2,1.5,0.4,2.2,0.4
            c0.8,0,1.5-0.2,2-0.5s0.7-0.8,0.7-1.4s-0.2-1-0.6-1.3s-1.1-0.5-2.1-0.5h-1.5v-2.2l4.1-4.6l0.4,1.2h-7.7v-2.7h10.2v2.2l-4,4.6
            l-1.7-1h1c1.8,0,3.1,0.4,4.1,1.2s1.4,1.8,1.4,3.1c0,0.8-0.2,1.6-0.6,2.3c-0.4,0.7-1.1,1.3-2,1.7C284.6,55.2,283.5,55.4,282.1,55.4
            z"/>
        </g>
        <g>
          <g transform="matrix(-1,0,0,1,451.5,137)">
            <polygon className="st2" points="174.5,-50.6 182.2,-50.6 182.2,-50.6 			"/>
          </g>
          <path className="st3" d="M277.2,86.4v-0.3h0.3v0.3H277.2z M269.2,94.5v0.3h-0.3v-0.3H269.2z M269.2,86.4h-0.3v-0.3h0.3V86.4z
            M277.5,86.4L277.5,86.4h-0.6l0,0H277.5z M277.5,86.4c0,4.6-3.7,8.4-8.3,8.4v-0.6c4.2,0,7.7-3.5,7.7-7.8H277.5z M269.2,94.8v-0.6
            V94.8z M268.9,94.5v-8.1h0.6v8.1H268.9z M269.2,86.1h8v0.6h-8V86.1z"/>
        </g>
      </g>
      <g id="room-04" onClick={() => room.set('room-04')}>
        <g transform="matrix(0.211324,0,0,1.0146,377.366,-2.02919)">
          <rect x="-322.7" y="2" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M-106.7,2h-215.9v84h215.9C-106.7,86-106.7,2-106.7,2z M-112.5,3.2v81.6h-204.3V3.2H-112.5z"/>
        </g>
        <g transform="matrix(1,0,0,1,3.68619,-3.92614)">
          <path className="st9" d="M321.2,53.1v-2.3l6.9-9.3h3.5l-6.7,9.3l-1.6-0.5h11.5v2.8H321.2z M329,56.2v-3.1l0.1-2.8v-2.7h3.2v8.5H329
            V56.2z"/>
        </g>
        <g>
          <g transform="matrix(-1,0,0,1,526.5,137)">
            <polygon className="st2" points="203.5,-50.6 211.2,-50.6 211.2,-50.6 			"/>
          </g>
          <path className="st3" d="M323.2,86.4v-0.3h0.3v0.3H323.2z M315.2,94.5v0.3h-0.3v-0.3H315.2z M315.2,86.4h-0.3v-0.3h0.3V86.4z
            M323.5,86.4L323.5,86.4h-0.6l0,0H323.5z M323.5,86.4c0,4.6-3.7,8.4-8.3,8.4v-0.6c4.2,0,7.7-3.5,7.7-7.8H323.5z M315.2,94.8v-0.6
            V94.8z M314.9,94.5v-8.1h0.6v8.1H314.9z M315.2,86.1h8v0.6h-8V86.1z"/>
        </g>
      </g>
      <g id="room-05" onClick={() => room.set('room-05')}>
        <g transform="matrix(0.923259,0,0,1.0146,118.846,-2.02919)">
          <rect x="318.2" y="2" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M534.1,2H318.2v84h215.9V2z M532.8,3.2v81.6c0,0-195.3,0-213.3,0V3.2H532.8L532.8,3.2z"/>
        </g>
        <g transform="matrix(1,0,0,1,0,3.26026)">
          <g transform="matrix(1,0,0,1,88.396,0)">
            <path className="st9" d="M446.2,53.6c-1,0-2-0.1-3-0.4s-1.8-0.7-2.5-1.2l1.3-2.6c0.6,0.4,1.2,0.7,1.9,1c0.7,0.2,1.5,0.4,2.2,0.4
              c0.8,0,1.5-0.2,2-0.5s0.7-0.8,0.7-1.4c0-0.4-0.1-0.7-0.3-1c-0.2-0.3-0.5-0.5-1-0.7s-1.2-0.2-2.1-0.2h-3.7l0.8-8.2h8.9v2.7h-7.7
              l1.8-1.5l-0.5,5.9l-1.8-1.5h3.1c1.4,0,2.6,0.2,3.5,0.6s1.5,0.9,1.9,1.6s0.6,1.5,0.6,2.3c0,0.9-0.2,1.7-0.6,2.4
              c-0.4,0.7-1.1,1.3-2,1.8C448.8,53.4,447.6,53.6,446.2,53.6z"/>
          </g>
          <g>
            <g transform="matrix(1,0,0,1,108.146,-9.12886)">
              <circle className="st4" cx="346" cy="28.4" r="4.9"/>
            </g>
            <g transform="matrix(1,0,0,1,108.146,-9.12886)">
              <circle className="st4" cx="338.4" cy="23.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,108.146,-9.12886)">
              <circle className="st4" cx="346.3" cy="19.5" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,108.146,-9.12886)">
              <circle className="st4" cx="353.7" cy="23.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,108.146,-9.12886)">
              <circle className="st4" cx="353.7" cy="33" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,108.146,-9.12886)">
              <circle className="st4" cx="346.3" cy="37.3" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,108.146,-9.12886)">
              <circle className="st4" cx="338.4" cy="33" r="2.8"/>
            </g>
          </g>
          <g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="391.1" cy="45.7" r="4.9"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="383.4" cy="41.1" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="391.4" cy="36.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="398.7" cy="41.1" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="398.7" cy="50.3" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="391.4" cy="54.6" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="383.4" cy="50.3" r="2.8"/>
            </g>
          </g>
          <g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="351.8" cy="60.4" r="4.9"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="344.2" cy="55.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="352.1" cy="51.5" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="359.5" cy="55.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="359.5" cy="65" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="352.1" cy="69.3" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="344.2" cy="65" r="2.8"/>
            </g>
          </g>
          <g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="419.3" cy="14.4" r="4.9"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="411.6" cy="9.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="419.6" cy="5.6" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="427" cy="9.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="427" cy="19" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="419.6" cy="23.3" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="411.6" cy="19" r="2.8"/>
            </g>
          </g>
          <g transform="matrix(1,0,0,1,88.396,0)">
            <circle className="st4" cx="425.4" cy="64.7" r="4.9"/>
          </g>
          <g transform="matrix(1,0,0,1,88.396,0)">
            <circle className="st4" cx="417.8" cy="60.1" r="2.8"/>
          </g>
          <g transform="matrix(1,0,0,1,88.396,0)">
            <circle className="st4" cx="425.7" cy="55.8" r="2.8"/>
          </g>
          <g transform="matrix(1,0,0,1,88.396,0)">
            <circle className="st4" cx="433.1" cy="60.1" r="2.8"/>
          </g>
          <g transform="matrix(1,0,0,1,88.396,0)">
            <circle className="st4" cx="433.1" cy="69.3" r="2.8"/>
          </g>
          <g transform="matrix(1,0,0,1,88.396,0)">
            <circle className="st4" cx="425.7" cy="73.6" r="2.8"/>
          </g>
          <g transform="matrix(1,0,0,1,88.396,0)">
            <circle className="st4" cx="417.8" cy="69.3" r="2.8"/>
          </g>
          <g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="461" cy="21.2" r="4.9"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="453.3" cy="16.6" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="461.3" cy="12.3" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="468.7" cy="16.6" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="468.7" cy="25.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="461.3" cy="30.1" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="453.3" cy="25.8" r="2.8"/>
            </g>
          </g>
          <g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="475.7" cy="64.1" r="4.9"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="468" cy="59.5" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="476" cy="55.2" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="483.4" cy="59.5" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="483.4" cy="68.7" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="476" cy="73" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="468" cy="68.7" r="2.8"/>
            </g>
          </g>
          <g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="497.8" cy="30.4" r="4.9"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="490.1" cy="25.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="498.1" cy="21.5" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="505.5" cy="25.8" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="505.5" cy="35" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="498.1" cy="39.3" r="2.8"/>
            </g>
            <g transform="matrix(1,0,0,1,88.396,0)">
              <circle className="st4" cx="490.1" cy="35" r="2.8"/>
            </g>
          </g>
        </g>
        <g transform="matrix(1,0,0,1,131,0)">
          <g transform="matrix(-1,0,0,1,642.5,137)">
            <polygon className="st2" points="299,-50.6 306.7,-50.6 306.7,-50.6 			"/>
          </g>
          <path className="st3" d="M343.6,86.4v-0.3h0.3v0.3H343.6z M335.7,94.5v0.3h-0.3v-0.3H335.7z M335.7,86.4h-0.3v-0.3h0.3V86.4z
            M343.9,86.4L343.9,86.4h-0.6l0,0H343.9z M343.9,86.4c0,4.6-3.7,8.4-8.3,8.4v-0.6c4.2,0,7.7-3.5,7.7-7.8H343.9z M335.7,94.8v-0.6
            V94.8z M335.4,94.5v-8.1h0.6v8.1H335.4z M335.7,86.1h8v0.6h-8V86.1z"/>
          <rect x="343.8" y="84.2" className="st2" width="7.7" height="2.1"/>
          <path className="st3" d="M343.6,86.4v-0.3h-0.3v0.3H343.6z M351.6,94.5v0.3h0.3v-0.3H351.6z M351.6,86.4h0.3v-0.3h-0.3V86.4z
            M343.3,86.4L343.3,86.4h0.6l0,0H343.3z M343.3,86.4c0,4.6,3.7,8.4,8.3,8.4v-0.6c-4.2,0-7.7-3.5-7.7-7.8H343.3z M351.6,94.8v-0.6
            V94.8z M351.9,94.5v-8.1h-0.6v8.1H351.9z M351.6,86.1h-8v0.6h8V86.1z"/>
        </g>
      </g>
      <g id="room-06" onClick={() => room.set('room-06')}>
        <g transform="matrix(0.457503,0,0,1.85851,562.353,135.293)">
          <rect x="-107.4" y="-26.9" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M108.5-26.9h-215.9v84h215.9V-26.9z M105.8-26.2v82.7h-210.6v-82.7H105.8z"/>
        </g>
        <g transform="matrix(1,0,0,1,-4.876,-0.02289)">
          <g transform="matrix(0.748493,0,0,0.748493,236.592,57.588)">
            <path className="st9" d="M441.3,138.6c-1.3,0-2.5-0.3-3.5-0.8c-1-0.6-1.7-1.4-2.3-2.4c-0.5-1.1-0.8-2.4-0.8-4c0-1.7,0.3-3.1,0.9-4.3
              c0.6-1.2,1.5-2.1,2.6-2.7s2.4-0.9,3.9-0.9c0.8,0,1.5,0.1,2.2,0.3c0.7,0.2,1.3,0.4,1.8,0.8l-1.3,2.5c-0.4-0.3-0.8-0.5-1.3-0.6
              s-0.9-0.2-1.4-0.2c-1.3,0-2.3,0.4-3,1.1c-0.7,0.8-1.1,1.9-1.1,3.4v0.8c0,0.3,0.1,0.6,0.1,0.9l-0.9-0.9c0.3-0.5,0.6-1,1-1.4
              s0.9-0.7,1.5-0.8c0.6-0.2,1.2-0.3,1.9-0.3c0.9,0,1.8,0.2,2.5,0.6s1.3,0.9,1.8,1.6s0.7,1.5,0.7,2.4c0,1-0.3,1.8-0.8,2.6
              c-0.5,0.7-1.1,1.3-2,1.7C443.3,138.4,442.4,138.6,441.3,138.6z M441.2,136.1c0.5,0,0.9-0.1,1.3-0.3c0.4-0.2,0.7-0.4,0.9-0.8
              c0.2-0.3,0.3-0.7,0.3-1.1c0-0.7-0.2-1.2-0.7-1.6c-0.4-0.4-1-0.6-1.8-0.6c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.7,0.4-0.9,0.8
              c-0.2,0.3-0.3,0.7-0.3,1.1s0.1,0.8,0.3,1.1c0.2,0.3,0.5,0.6,0.9,0.8C440.2,136,440.6,136.1,441.2,136.1z"/>
          </g>
          <rect x="525.3" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="525.3" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="532" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="532" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="538.8" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="538.8" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="545.5" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="545.5" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="552.3" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="552.3" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="574.3" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="574.3" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="581.1" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="581.1" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="587.8" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="587.8" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="594.6" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="594.6" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="601.3" y="92.4" className="st4" width="6.1" height="1.8"/>
          <rect x="601.3" y="94.8" className="st4" width="6.1" height="4.9"/>
          <rect x="525.3" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="525.3" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="532" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="532" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="538.8" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="538.8" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="545.5" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="545.5" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="552.3" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="552.3" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="574.3" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="574.3" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="581.1" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="581.1" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="587.8" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="587.8" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="594.6" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="594.6" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="601.3" y="106.5" className="st4" width="6.1" height="1.8"/>
          <rect x="601.3" y="108.9" className="st4" width="6.1" height="4.9"/>
          <rect x="525.3" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="525.3" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="532" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="532" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="538.8" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="538.8" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="545.5" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="545.5" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="552.3" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="552.3" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="574.3" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="574.3" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="581.1" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="581.1" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="587.8" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="587.8" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="594.6" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="594.6" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="601.3" y="120.6" className="st4" width="6.1" height="1.8"/>
          <rect x="601.3" y="123" className="st4" width="6.1" height="4.9"/>
          <rect x="525.3" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="525.3" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="532" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="532" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="538.8" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="538.8" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="545.5" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="545.5" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="552.3" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="552.3" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="574.3" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="574.3" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="581.1" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="581.1" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="587.8" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="587.8" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="594.6" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="594.6" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="601.3" y="134.7" className="st4" width="6.1" height="1.8"/>
          <rect x="601.3" y="137.1" className="st4" width="6.1" height="4.9"/>
          <rect x="525.3" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="525.3" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="532" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="532" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="538.8" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="538.8" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="545.5" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="545.5" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="552.3" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="552.3" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="574.3" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="574.3" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="581.1" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="581.1" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="587.8" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="587.8" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="594.6" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="594.6" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="601.3" y="148.8" className="st4" width="6.1" height="1.8"/>
          <rect x="601.3" y="151.2" className="st4" width="6.1" height="4.9"/>
          <rect x="525.3" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="525.3" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="532" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="532" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="538.8" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="538.8" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="545.5" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="545.5" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="552.3" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="552.3" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="574.3" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="574.3" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="581.1" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="581.1" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="587.8" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="587.8" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="594.6" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="594.6" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="601.3" y="162.9" className="st4" width="6.1" height="1.8"/>
          <rect x="601.3" y="165.3" className="st4" width="6.1" height="4.9"/>
          <rect x="525.3" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="525.3" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="532" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="532" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="538.8" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="538.8" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="545.5" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="545.5" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="552.3" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="552.3" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="574.3" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="574.3" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="581.1" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="581.1" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="587.8" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="587.8" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="594.6" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="594.6" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="601.3" y="177" className="st4" width="6.1" height="1.8"/>
          <rect x="601.3" y="179.4" className="st4" width="6.1" height="4.9"/>
          <rect x="525.3" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="525.3" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="532" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="532" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="538.8" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="538.8" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="545.5" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="545.5" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="552.3" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="552.3" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="574.3" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="574.3" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="581.1" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="581.1" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="587.8" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="587.8" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="594.6" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="594.6" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="601.3" y="191.1" className="st4" width="6.1" height="1.8"/>
          <rect x="601.3" y="193.6" className="st4" width="6.1" height="4.9"/>
          <rect x="529" y="204" className="st4" width="75.4" height="28.4"/>
          <rect x="531.6" y="206.6" className="st4" width="70.2" height="25.7"/>
          <rect x="534.6" y="209.8" className="st4" width="64.1" height="22.6"/>
          <rect x="537.2" y="212.4" className="st4" width="58.9" height="19.9"/>
          <rect x="542" y="216.1" className="st4" width="19.8" height="7.3"/>
          <rect x="540.3" y="230.9" className="st4" width="52.8" height="2.5"/>
        </g>
        <g>
          <g transform="matrix(4.37114e-08,-1,-1,-4.37114e-08,836.501,170.5)">
            <polygon className="st2" points="73.4,325.5 73.4,323.4 73.4,323.4 			"/>
          </g>
          <path className="st3" d="M511,104.9h0.3v0.3H511V104.9z M502.8,97h-0.3v-0.3h0.3V97z M511,97v-0.3h0.3V97H511z M511,105.2L511,105.2
            v-0.6l0,0V105.2z M511,105.2c-4.6,0-8.4-3.7-8.4-8.3h0.6c0,4.2,3.5,7.7,7.8,7.7V105.2z M502.5,97h0.6H502.5z M502.8,96.7h8.1v0.6
            h-8.1V96.7z M511.3,97v8h-0.6v-8H511.3z"/>
          <g transform="matrix(-3.82857e-16,1,-1,-3.82857e-16,1008,-665.501)">
            
              <rect x="773.3" y="492" transform="matrix(2.535182e-06 1 -1 2.535182e-06 1270.2626 -278.4958)" className="st2" width="2.1" height="7.7"/>
          </g>
          <path className="st3" d="M511,104.9h0.3v-0.3H511V104.9z M502.8,112.9h-0.3v0.3h0.3V112.9z M511,112.9v0.3h0.3v-0.3H511z M511,104.6
            L511,104.6v0.6l0,0V104.6z M511,104.6c-4.6,0-8.4,3.7-8.4,8.3h0.6c0-4.2,3.5-7.7,7.8-7.7V104.6z M502.5,112.9h0.6H502.5z
            M502.8,113.2h8.1v-0.6h-8.1V113.2z M511.3,112.9v-8h-0.6v8H511.3z"/>
        </g>
      </g>
      <g id="room-07" onClick={() => room.set('room-07')}>
        <g transform="matrix(0.902399,0,0,1.95794,-150.497,185.13)">
          <rect x="432.5" y="-35.4" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M648.4-35.4H432.5v84h215.9V-35.4z M647.1-34.7v82.8H433.9c0-7.3,0-75.5,0-82.8L647.1-34.7L647.1-34.7z"/>
        </g>
        <g>
          <path className="st9" d="M406,138.7l5.9-13.3l0.9,1.5h-7.9l1.5-1.6v4h-3v-5.1h11.7v2.2l-5.4,12.4H406V138.7z"/>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,-26,857)">
            
              <rect x="588.3" y="278.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 307.4643 871.0071)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,-22,861)">
            
              <rect x="590.8" y="278.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 311.5423 874.9573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,-15,846)">
            
              <rect x="584.1" y="267.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 314.2643 855.6981)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,-11,850)">
            
              <rect x="586.5" y="267.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 318.2423 859.7481)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,-4,835)">
            
              <rect x="579.8" y="256.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 320.9643 840.4891)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,1.7053e-13,839)">
            
              <rect x="582.3" y="256.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 325.0423 844.4391)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,7,824)">
            
              <rect x="575.6" y="245.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 327.7643 825.18)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,11,828)">
            
              <rect x="578" y="245.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 331.7423 829.23)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,18,813)">
            
              <rect x="571.3" y="234.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 334.4642 809.9708)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,22,817)">
            
              <rect x="573.8" y="234.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 338.5424 813.9209)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,29,802)">
            
              <rect x="567.1" y="223.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 341.2643 794.6617)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,33,806)">
            
              <rect x="569.5" y="223.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 345.2423 798.7119)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,40,791)">
            
              <rect x="562.8" y="212.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 347.9643 779.4527)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,44,795)">
            
              <rect x="565.3" y="212.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 352.0424 783.4026)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,51,780)">
            
              <rect x="558.6" y="201.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 354.7643 764.1436)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,55,784)">
            
              <rect x="561" y="201.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 358.7423 768.1937)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,62,769)">
            
              <rect x="554.3" y="190.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 361.4643 748.9346)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,66,773)">
            
              <rect x="556.8" y="190.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 365.5424 752.8845)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,73,758)">
            
              <rect x="550" y="179.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 368.1643 733.7254)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,77,762)">
            
              <rect x="552.5" y="179.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 372.2424 737.6755)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,84,747)">
            
              <rect x="545.8" y="168.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 374.9643 718.4163)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,88,751)">
            
              <rect x="548.3" y="168.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 379.0424 722.3665)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,95,736)">
            
              <rect x="541.5" y="157.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 381.6643 703.2073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,99,740)">
            
              <rect x="544" y="157.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 385.7424 707.1572)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,106,725)">
            
              <rect x="537.3" y="146.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 388.4644 687.8982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,110,729)">
            
              <rect x="539.7" y="146.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 392.4424 691.9482)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,142,689)">
            
              <rect x="523.4" y="110.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 410.5643 637.9503)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,146,693)">
            
              <rect x="525.8" y="110.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 414.5423 642.0003)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,153,678)">
            
              <rect x="519.1" y="99.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 417.2643 622.7413)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,157,682)">
            
              <rect x="521.6" y="99.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 421.3425 626.6912)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,164,667)">
            
              <rect x="514.8" y="88.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 423.9644 607.5322)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,168,671)">
            
              <rect x="517.3" y="88.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 428.0424 611.4822)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,175,656)">
            
              <rect x="510.6" y="77.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 430.7643 592.223)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,179,660)">
            
              <rect x="513.1" y="77.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 434.8424 596.1731)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,186,645)">
            
              <rect x="506.3" y="66.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 437.4644 577.0139)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,190,649)">
            
              <rect x="508.8" y="66.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 441.5425 580.964)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,165,712)">
            
              <rect x="546.5" y="101.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 442.556 652.1503)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,169,716)">
            
              <rect x="548.8" y="101.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 446.4341 656.1003)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,176,701)">
            
              <rect x="542.2" y="90.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 449.2561 636.9412)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,180,705)">
            
              <rect x="544.6" y="90.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 453.2342 640.7913)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,187,690)">
            
              <rect x="537.9" y="79.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 455.9561 621.7321)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,191,694)">
            
              <rect x="540.3" y="79.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 459.9341 625.5821)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,198,679)">
            
              <rect x="533.7" y="68.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 462.756 606.4231)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,202,683)">
            
              <rect x="536.1" y="68.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 466.7342 610.273)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,209,668)">
            
              <rect x="529.4" y="57.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 469.4561 591.214)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,213,672)">
            
              <rect x="531.8" y="57.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 473.4341 595.064)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,188,735)">
            
              <rect x="569.4" y="92.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 474.4478 666.2502)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,192,739)">
            
              <rect x="571.8" y="92.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 478.3258 670.2003)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,199,724)">
            
              <rect x="565.2" y="81.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 481.1478 651.0412)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,203,728)">
            
              <rect x="567.6" y="81.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 485.1259 654.8912)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,210,713)">
            
              <rect x="560.9" y="70.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 487.8478 635.8322)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,214,717)">
            
              <rect x="563.3" y="70.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 491.826 639.6821)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,221,702)">
            
              <rect x="556.7" y="59.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 494.6478 620.5229)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,225,706)">
            
              <rect x="559" y="59.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 498.6259 624.373)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,232,691)">
            
              <rect x="552.4" y="48.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 501.3478 605.314)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,236,695)">
            
              <rect x="554.8" y="48.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 505.3258 609.1639)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,211,758)">
            
              <rect x="592.4" y="84" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 506.3395 680.3502)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,215,762)">
            
              <rect x="594.8" y="84" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 510.2176 684.3003)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,222,747)">
            
              <rect x="588.2" y="73" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 513.0395 665.1412)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,226,751)">
            
              <rect x="590.6" y="72.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 517.0176 668.9913)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,233,736)">
            
              <rect x="583.9" y="62" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 519.7395 649.9322)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,237,740)">
            
              <rect x="586.3" y="62" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 523.7177 653.7822)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,244,725)">
            
              <rect x="579.7" y="51" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 526.5396 634.623)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,248,729)">
            
              <rect x="582" y="50.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 530.5176 638.473)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,255,714)">
            
              <rect x="575.4" y="40" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 533.2396 619.4141)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,259,718)">
            
              <rect x="577.8" y="40" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 537.2175 623.264)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,234,781)">
            
              <rect x="615.4" y="75.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 538.2313 694.4503)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,238,785)">
            
              <rect x="617.9" y="75.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 542.2093 698.5003)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,245,770)">
            
              <rect x="611.2" y="64.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 544.9313 679.2413)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,249,774)">
            
              <rect x="613.6" y="64" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 549.0093 683.1913)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,256,759)">
            
              <rect x="606.9" y="53.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 551.6312 664.0322)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,260,763)">
            
              <rect x="609.4" y="53.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 555.7094 667.9821)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,267,748)">
            
              <rect x="602.7" y="42.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 558.4312 648.723)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,271,752)">
            
              <rect x="605.1" y="42" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 562.5093 652.6731)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,278,737)">
            
              <rect x="598.4" y="31.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 565.1312 633.514)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,282,741)">
            
              <rect x="600.9" y="31.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 569.2094 637.4641)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,257,804)">
            
              <rect x="638.4" y="66.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 570.1229 708.5503)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,261,808)">
            
              <rect x="640.9" y="66.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 574.101 712.6004)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,268,793)">
            
              <rect x="634.2" y="55.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 576.8229 693.3412)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,272,797)">
            
              <rect x="636.6" y="55.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 580.901 697.2913)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,279,782)">
            
              <rect x="629.9" y="44.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 583.523 678.1321)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,283,786)">
            
              <rect x="632.4" y="44.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 587.601 682.0822)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,290,771)">
            
              <rect x="625.7" y="33.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 590.323 662.8231)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,294,775)">
            
              <rect x="628.1" y="33.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 594.401 666.7731)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,301,760)">
            
              <rect x="621.4" y="22.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 597.0229 647.6141)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,305,764)">
            
              <rect x="623.9" y="22.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 601.1011 651.564)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,280,827)">
            
              <rect x="661.4" y="57.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 602.0147 722.6504)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,284,831)">
            
              <rect x="663.9" y="57.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 605.9927 726.7003)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,291,816)">
            
              <rect x="657.2" y="46.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 608.7147 707.4413)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,295,820)">
            
              <rect x="659.6" y="46.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 612.7928 711.3912)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,302,805)">
            
              <rect x="652.9" y="35.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 615.4147 692.2321)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,306,809)">
            
              <rect x="655.4" y="35.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 619.4928 696.1822)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,313,794)">
            
              <rect x="648.7" y="24.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 622.2147 676.923)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,317,798)">
            
              <rect x="651.1" y="24.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 626.2927 680.8732)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,324,783)">
            
              <rect x="644.4" y="13.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 628.9146 661.7141)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,328,787)">
            
              <rect x="646.9" y="13.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 632.9927 665.6639)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,303,850)">
            
              <rect x="684.4" y="48.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 633.9064 736.7505)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,307,854)">
            
              <rect x="686.9" y="48.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 637.8845 740.8004)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,314,839)">
            
              <rect x="680.2" y="37.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 640.6063 721.5413)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,318,843)">
            
              <rect x="682.6" y="37.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 644.6846 725.4913)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,325,828)">
            
              <rect x="675.9" y="26.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 647.3064 706.3322)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,329,832)">
            
              <rect x="678.4" y="26.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 651.3844 710.2822)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,336,817)">
            
              <rect x="671.7" y="15.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 654.1064 691.0231)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,340,821)">
            
              <rect x="674.1" y="15.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 658.1844 694.9731)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,347,806)">
            
              <rect x="667.4" y="4.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 660.8064 675.8141)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,351,810)">
            
              <rect x="669.9" y="4.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 664.8845 679.764)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,326,873)">
            
              <rect x="707.4" y="39.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 665.7981 750.8504)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,330,877)">
            
              <rect x="709.9" y="39.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 669.7761 754.9005)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,337,862)">
            
              <rect x="703.2" y="28.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 672.4982 735.6413)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,341,866)">
            
              <rect x="705.6" y="28.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 676.5762 739.5912)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,348,851)">
            
              <rect x="698.9" y="17.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 679.1981 720.4323)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,352,855)">
            
              <rect x="701.4" y="17.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 683.2762 724.3823)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,359,840)">
            
              <rect x="694.7" y="6.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 685.9981 705.1231)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,363,844)">
            
              <rect x="697.1" y="6.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 690.0762 709.0732)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,370,829)">
            
              <rect x="690.4" y="-4.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 692.6981 689.914)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,374,833)">
            
              <rect x="692.9" y="-4.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 696.7762 693.864)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,-3,880)">
            
              <rect x="611.4" y="269.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 339.456 885.2072)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,1,884)">
            
              <rect x="613.8" y="269.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 343.4341 889.0572)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,8,869)">
            
              <rect x="607.2" y="258.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 346.2559 869.8981)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,12,873)">
            
              <rect x="609.5" y="258.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 350.1341 873.8481)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,19,858)">
            
              <rect x="602.9" y="247.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 352.9559 854.689)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,23,862)">
            
              <rect x="605.3" y="247.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 356.934 858.5392)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,30,847)">
            
              <rect x="598.7" y="236.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 359.756 839.3799)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,34,851)">
            
              <rect x="601" y="236.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 363.6341 843.3299)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,41,836)">
            
              <rect x="594.4" y="225.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 366.456 824.1709)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,45,840)">
            
              <rect x="596.8" y="225.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 370.434 828.0209)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,52,825)">
            
              <rect x="590.2" y="214.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 373.256 808.8618)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,56,829)">
            
              <rect x="592.5" y="214.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 377.1341 812.8117)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,63,814)">
            
              <rect x="585.9" y="203.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 379.956 793.6526)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,67,818)">
            
              <rect x="588.3" y="203.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 383.9341 797.5027)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,74,803)">
            
              <rect x="581.6" y="192.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 386.756 778.3436)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,78,807)">
            
              <rect x="584" y="192.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 390.6342 782.2936)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,85,792)">
            
              <rect x="577.4" y="181.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 393.456 763.1346)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,89,796)">
            
              <rect x="579.8" y="181.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 397.4341 766.9844)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,96,781)">
            
              <rect x="573.1" y="170.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 400.1561 747.9254)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,100,785)">
            
              <rect x="575.5" y="170.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 404.1341 751.7755)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,107,770)">
            
              <rect x="568.9" y="159.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 406.9561 732.6164)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,111,774)">
            
              <rect x="571.2" y="159.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 410.9342 736.4663)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,118,759)">
            
              <rect x="564.6" y="148.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 413.656 717.4073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,122,763)">
            
              <rect x="567" y="148.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 417.6342 721.2573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,129,748)">
            
              <rect x="560.4" y="137.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 420.456 702.0982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,133,752)">
            
              <rect x="562.7" y="137.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 424.3341 706.0483)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,20,903)">
            
              <rect x="634.4" y="260.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 371.3477 899.3072)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,24,907)">
            
              <rect x="636.8" y="260.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 375.3257 903.1572)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,31,892)">
            
              <rect x="630.2" y="249.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 378.1477 883.9981)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,35,896)">
            
              <rect x="632.5" y="249.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 382.0257 887.9482)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,42,881)">
            
              <rect x="625.9" y="238.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 384.8477 868.7891)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,46,885)">
            
              <rect x="628.3" y="238.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 388.8258 872.639)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,53,870)">
            
              <rect x="621.7" y="227.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 391.6478 853.48)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,57,874)">
            
              <rect x="624" y="227.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 395.5258 857.43)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,64,859)">
            
              <rect x="617.4" y="216.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 398.3477 838.2709)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,68,863)">
            
              <rect x="619.8" y="216.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 402.3259 842.1208)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,75,848)">
            
              <rect x="613.2" y="205.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 405.1476 822.9619)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,79,852)">
            
              <rect x="615.5" y="205.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 409.0258 826.9117)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,86,837)">
            
              <rect x="608.9" y="194.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 411.8477 807.7527)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,90,841)">
            
              <rect x="611.3" y="194.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 415.8257 811.6027)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,97,826)">
            
              <rect x="604.6" y="183.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 418.6478 792.4437)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,101,830)">
            
              <rect x="607" y="183.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 422.5259 796.3936)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,108,815)">
            
              <rect x="600.4" y="172.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 425.3477 777.2347)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,112,819)">
            
              <rect x="602.8" y="172.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 429.3258 781.0846)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,119,804)">
            
              <rect x="596.1" y="161.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 432.0477 762.0254)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,123,808)">
            
              <rect x="598.5" y="161.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 436.0258 765.8755)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,130,793)">
            
              <rect x="591.9" y="150.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 438.8477 746.7164)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,134,797)">
            
              <rect x="594.2" y="150.8" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 442.8258 750.5663)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,141,782)">
            
              <rect x="587.6" y="139.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 445.5478 731.5073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,145,786)">
            
              <rect x="590" y="139.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 449.5258 735.3573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,152,771)">
            
              <rect x="583.4" y="128.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 452.3477 716.1982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,156,775)">
            
              <rect x="585.7" y="128.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 456.2258 720.1483)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,43,926)">
            
              <rect x="657.4" y="252" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 403.2395 913.4073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,47,930)">
            
              <rect x="659.8" y="252" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 407.2174 917.2573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,54,915)">
            
              <rect x="653.2" y="241" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 410.0394 898.0981)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,58,919)">
            
              <rect x="655.5" y="241" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 413.9176 902.0481)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,65,904)">
            
              <rect x="648.9" y="230" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 416.7393 882.8892)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,69,908)">
            
              <rect x="651.3" y="230" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 420.7175 886.7391)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,76,893)">
            
              <rect x="644.7" y="219" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 423.5395 867.58)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,80,897)">
            
              <rect x="647" y="219" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 427.4174 871.53)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,87,882)">
            
              <rect x="640.4" y="208" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 430.2394 852.3709)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,91,886)">
            
              <rect x="642.8" y="208" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 434.2176 856.2208)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,98,871)">
            
              <rect x="636.1" y="197" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 437.0394 837.0619)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,102,875)">
            
              <rect x="638.5" y="197" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 440.9175 841.0118)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,109,860)">
            
              <rect x="631.9" y="186" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 443.7395 821.8528)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,113,864)">
            
              <rect x="634.3" y="185.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 447.7175 825.7028)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,120,849)">
            
              <rect x="627.6" y="175" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 450.5394 806.5437)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,124,853)">
            
              <rect x="630" y="175" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 454.4176 810.4937)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,131,838)">
            
              <rect x="623.4" y="164" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 457.2395 791.3345)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,135,842)">
            
              <rect x="625.8" y="163.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 461.2175 795.1845)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,142,827)">
            
              <rect x="619.1" y="153" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 463.9395 776.1255)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,146,831)">
            
              <rect x="621.5" y="153" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 467.9175 779.9755)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,153,816)">
            
              <rect x="614.9" y="142" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 470.7394 760.8165)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,157,820)">
            
              <rect x="617.2" y="141.9" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 474.7176 764.6664)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,164,805)">
            
              <rect x="610.6" y="131" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 477.4396 745.6074)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,168,809)">
            
              <rect x="613" y="131" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 481.4176 749.4573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,175,794)">
            
              <rect x="606.4" y="120" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 484.2394 730.2982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,179,798)">
            
              <rect x="608.7" y="120" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 488.1176 734.2483)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,66,949)">
            
              <rect x="680.4" y="243.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 435.1311 927.5072)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,70,953)">
            
              <rect x="682.9" y="243.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 439.2091 931.4573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,77,938)">
            
              <rect x="676.2" y="232.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 441.9312 912.1982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,81,942)">
            
              <rect x="678.6" y="232.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 445.9093 916.248)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,88,927)">
            
              <rect x="671.9" y="221.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 448.6311 896.9892)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,92,931)">
            
              <rect x="674.4" y="221.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 452.7092 900.9391)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,99,916)">
            
              <rect x="667.7" y="210.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 455.4312 881.6801)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,103,920)">
            
              <rect x="670.1" y="210.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 459.4092 885.73)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,110,905)">
            
              <rect x="663.4" y="199.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 462.1311 866.4709)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,114,909)">
            
              <rect x="665.9" y="199.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 466.2093 870.421)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,121,894)">
            
              <rect x="659.1" y="188.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 468.9312 851.1617)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,125,898)">
            
              <rect x="661.6" y="188.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 472.9092 855.2118)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,132,883)">
            
              <rect x="654.9" y="177.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 475.6311 835.9528)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,136,887)">
            
              <rect x="657.4" y="177" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 479.7092 839.9028)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,143,872)">
            
              <rect x="650.6" y="166.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 482.4313 820.6436)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,147,876)">
            
              <rect x="653.1" y="166.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 486.4093 824.6937)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,154,861)">
            
              <rect x="646.4" y="155.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 489.1312 805.4345)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,158,865)">
            
              <rect x="648.8" y="155" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 493.2093 809.3846)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,165,850)">
            
              <rect x="642.1" y="144.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 495.8311 790.2255)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,169,854)">
            
              <rect x="644.6" y="144.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 499.9093 794.1755)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,176,839)">
            
              <rect x="637.9" y="133.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 502.6313 774.9164)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,180,843)">
            
              <rect x="640.3" y="133" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 506.7094 778.8665)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,187,828)">
            
              <rect x="633.6" y="122.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 509.3312 759.7073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,191,832)">
            
              <rect x="636.1" y="122.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 513.4092 763.6573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,198,817)">
            
              <rect x="629.4" y="111.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 516.1313 744.3982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,202,821)">
            
              <rect x="631.8" y="111.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 520.1091 748.4483)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,89,972)">
            
              <rect x="703.4" y="234.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 467.0229 941.6073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,93,976)">
            
              <rect x="705.9" y="234.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 471.1009 945.5573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,100,961)">
            
              <rect x="699.2" y="223.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 473.8228 926.2982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,104,965)">
            
              <rect x="701.6" y="223.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 477.801 930.3482)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,111,950)">
            
              <rect x="694.9" y="212.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 480.5229 911.0891)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,115,954)">
            
              <rect x="697.4" y="212.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 484.601 915.0391)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,122,939)">
            
              <rect x="690.7" y="201.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 487.3228 895.78)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,126,943)">
            
              <rect x="693.1" y="201.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 491.3009 899.83)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,133,928)">
            
              <rect x="686.4" y="190.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 494.0229 880.5709)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,137,932)">
            
              <rect x="688.9" y="190.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 498.1009 884.5209)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,144,917)">
            
              <rect x="682.1" y="179.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 500.8229 865.2619)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,148,921)">
            
              <rect x="684.6" y="179.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 504.801 869.3119)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,155,906)">
            
              <rect x="677.9" y="168.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 507.5229 850.0529)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,159,910)">
            
              <rect x="680.4" y="168.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 511.601 854.0028)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,166,895)">
            
              <rect x="673.6" y="157.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 514.3229 834.7436)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,170,899)">
            
              <rect x="676.1" y="157.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 518.301 838.7937)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,177,884)">
            
              <rect x="669.4" y="146.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 521.0229 819.5346)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,181,888)">
            
              <rect x="671.8" y="146.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 525.1009 823.4846)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,188,873)">
            
              <rect x="665.1" y="135.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 527.723 804.3254)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,192,877)">
            
              <rect x="667.6" y="135.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 531.8009 808.2756)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,199,862)">
            
              <rect x="660.9" y="124.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 534.5229 789.0164)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,203,866)">
            
              <rect x="663.3" y="124.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 538.601 792.9664)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,210,851)">
            
              <rect x="656.6" y="113.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 541.2228 773.8073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,214,855)">
            
              <rect x="659.1" y="113.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 545.301 777.7574)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,221,840)">
            
              <rect x="652.4" y="102.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 548.0229 758.4982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,225,844)">
            
              <rect x="654.8" y="102.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 552.0009 762.5484)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,112,995)">
            
              <rect x="726.4" y="225.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 498.9145 955.7073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,116,999)">
            
              <rect x="728.9" y="225.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 502.9926 959.6573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,123,984)">
            
              <rect x="722.2" y="214.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 505.7147 940.3981)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,127,988)">
            
              <rect x="724.6" y="214.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 509.6926 944.4481)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,134,973)">
            
              <rect x="717.9" y="203.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 512.4146 925.1891)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,138,977)">
            
              <rect x="720.4" y="203.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 516.4926 929.1392)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,145,962)">
            
              <rect x="713.6" y="192.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 519.2146 909.8799)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,149,966)">
            
              <rect x="716.1" y="192.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 523.1927 913.9301)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,156,951)">
            
              <rect x="709.4" y="181.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 525.9146 894.6708)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,160,955)">
            
              <rect x="711.9" y="181.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 529.9927 898.621)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,167,940)">
            
              <rect x="705.1" y="170.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 532.7146 879.3619)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,171,944)">
            
              <rect x="707.6" y="170.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 536.6927 883.4119)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,178,929)">
            
              <rect x="700.9" y="159.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 539.4147 864.1527)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,182,933)">
            
              <rect x="703.3" y="159.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 543.4926 868.1028)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,189,918)">
            
              <rect x="696.6" y="148.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 546.2147 848.8437)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,193,922)">
            
              <rect x="699.1" y="148.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 550.1927 852.8937)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,200,907)">
            
              <rect x="692.4" y="137.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 552.9146 833.6347)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,204,911)">
            
              <rect x="694.8" y="137.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 556.9926 837.5847)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,211,896)">
            
              <rect x="688.1" y="126.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 559.6146 818.4255)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,215,900)">
            
              <rect x="690.6" y="126.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 563.6926 822.3756)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,222,885)">
            
              <rect x="683.9" y="115.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 566.4146 803.1165)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,226,889)">
            
              <rect x="686.3" y="115.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 570.4927 807.0664)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,233,874)">
            
              <rect x="679.6" y="104.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 573.1146 787.9074)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,237,878)">
            
              <rect x="682.1" y="104.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 577.1926 791.8574)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,244,863)">
            
              <rect x="675.4" y="93.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 579.9147 772.5983)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,248,867)">
            
              <rect x="677.8" y="93.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 583.8928 776.6483)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,135,1018)">
            
              <rect x="749.4" y="216.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 530.8065 969.8073)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,139,1022)">
            
              <rect x="751.9" y="216.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 534.8844 973.7573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,146,1007)">
            
              <rect x="745.2" y="205.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 537.6064 954.4982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,150,1011)">
            
              <rect x="747.6" y="205.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 541.5844 958.5482)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,157,996)">
            
              <rect x="740.9" y="194.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 544.3063 939.2891)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,161,1000)">
            
              <rect x="743.4" y="194.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 548.3843 943.2391)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,168,985)">
            
              <rect x="736.6" y="183.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 551.1064 923.9799)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,172,989)">
            
              <rect x="739.1" y="183.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 555.0842 928.0301)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,179,974)">
            
              <rect x="732.4" y="172.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 557.8063 908.7709)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,183,978)">
            
              <rect x="734.9" y="172.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 561.8845 912.7209)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,190,963)">
            
              <rect x="728.1" y="161.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 564.6063 893.4619)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,194,967)">
            
              <rect x="730.6" y="161.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 568.5843 897.512)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,201,952)">
            
              <rect x="723.9" y="150.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 571.3063 878.2527)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,205,956)">
            
              <rect x="726.3" y="150.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 575.3845 882.2028)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,212,941)">
            
              <rect x="719.6" y="139.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 578.1063 862.9437)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,216,945)">
            
              <rect x="722.1" y="139.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 582.0845 866.9937)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,223,930)">
            
              <rect x="715.4" y="128.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 584.8063 847.7346)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,227,934)">
            
              <rect x="717.8" y="128.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 588.8843 851.6847)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,234,919)">
            
              <rect x="711.1" y="117.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 591.5064 832.5255)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,238,923)">
            
              <rect x="713.6" y="117.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 595.5844 836.4755)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,245,908)">
            
              <rect x="706.9" y="106.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 598.3064 817.2165)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,249,912)">
            
              <rect x="709.3" y="106.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 602.3844 821.1665)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,256,897)">
            
              <rect x="702.6" y="95.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 605.0064 802.0074)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,260,901)">
            
              <rect x="705.1" y="95.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 609.0845 805.9573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,267,886)">
            
              <rect x="698.4" y="84.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 611.8064 786.6982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,271,890)">
            
              <rect x="700.8" y="84.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 615.7843 790.7482)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,158,1041)">
            
              <rect x="772.4" y="207.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 562.6981 983.9072)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,162,1045)">
            
              <rect x="774.9" y="207.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 566.7761 987.8574)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,169,1030)">
            
              <rect x="768.1" y="196.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 569.498 968.5982)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,173,1034)">
            
              <rect x="770.6" y="196.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 573.4761 972.6482)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,180,1019)">
            
              <rect x="763.9" y="185.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 576.1981 953.3892)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,184,1023)">
            
              <rect x="766.4" y="185.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 580.2761 957.3392)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,191,1008)">
            
              <rect x="759.6" y="174.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 582.9981 938.0801)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,195,1012)">
            
              <rect x="762.1" y="174.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 586.9761 942.1301)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,202,997)">
            
              <rect x="755.4" y="163.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 589.698 922.8709)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,206,1001)">
            
              <rect x="757.8" y="163.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 593.7761 926.821)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,213,986)">
            
              <rect x="751.1" y="152.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 596.498 907.5619)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,217,990)">
            
              <rect x="753.6" y="152.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 600.476 911.6119)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,224,975)">
            
              <rect x="746.9" y="141.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 603.1981 892.3528)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,228,979)">
            
              <rect x="749.3" y="141.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 607.2762 896.3027)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,235,964)">
            
              <rect x="742.6" y="130.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 609.9981 877.0437)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,239,968)">
            
              <rect x="745.1" y="130.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 613.9761 881.0938)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,246,953)">
            
              <rect x="738.4" y="119.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 616.6981 861.8347)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,250,957)">
            
              <rect x="740.8" y="119.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 620.7762 865.7845)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,257,942)">
            
              <rect x="734.1" y="108.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 623.398 846.6255)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,261,946)">
            
              <rect x="736.6" y="108.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 627.4761 850.5756)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,268,931)">
            
              <rect x="729.9" y="97.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 630.1981 831.3164)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,272,935)">
            
              <rect x="732.3" y="97.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 634.2763 835.2664)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,279,920)">
            
              <rect x="725.6" y="86.6" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 636.898 816.1075)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,283,924)">
            
              <rect x="728.1" y="86.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 640.9762 820.0573)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,290,909)">
            
              <rect x="721.3" y="75.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 643.6981 800.7983)" className="st4" width="1.8" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,294,913)">
            
              <rect x="723.8" y="75.5" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 647.6761 804.8483)" className="st4" width="4.9" height="6.1"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,227,1048)">
            
              <rect x="833" y="127.1" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 669.0687 1030.0465)" className="st4" width="33.1" height="106.7"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,238,1047)">
            
              <rect x="833.6" y="121.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 677.5527 1019.6966)" className="st4" width="30" height="99.3"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,251,1046)">
            
              <rect x="834.4" y="114.4" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 687.8232 1007.4465)" className="st4" width="26.4" height="90.8"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,262,1045)">
            
              <rect x="835" y="108.7" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 696.2072 996.9964)" className="st4" width="23.3" height="83.4"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,280,1041)">
            
              <rect x="817.4" y="115.2" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 692.3893 951.0467)" className="st4" width="8.6" height="28.2"/>
          </g>
          <g transform="matrix(-3.82857e-16,-1,1,-3.82857e-16,304,1073)">
            
              <rect x="873" y="82.3" transform="matrix(-2.535182e-06 -1 1 -2.535182e-06 754.818 994.2963)" className="st4" width="3.1" height="74.8"/>
          </g>
        </g>
        <g>
          <g transform="matrix(-1,7.65714e-16,-7.65714e-16,-1,829.5,381.502)">
            <rect x="575.2" y="264.5" className="st2" width="7.7" height="2.1"/>
          </g>
          <path className="st3" d="M254.5,114.9v0.3h0.3v-0.3H254.5z M246.5,106.8v-0.3V106.8z M246.5,106.8v-0.3h-0.3v0.3H246.5z M246.5,114.9
            h-0.3v0.3h0.3V114.9z M254.8,114.9L254.8,114.9h-0.6l0,0H254.8z M254.8,114.9c0-4.6-3.7-8.4-8.3-8.4v0.6c4.2,0,7.7,3.5,7.7,7.8
            H254.8z M246.5,106.5v0.6V106.5z M246.2,106.8v8.1h0.6v-8.1H246.2z M246.5,115.2h8v-0.6h-8V115.2z"/>
          <g transform="matrix(1,8.74228e-08,8.74228e-08,-1,415,190.501)">
            <polygon className="st2" points="-160.4,75.6 -152.7,75.6 -152.7,75.6 			"/>
          </g>
          <path className="st3" d="M254.5,114.9v0.3h-0.3v-0.3H254.5z M262.5,106.8v0.3V106.8z M262.5,106.8v-0.3h0.3v0.3H262.5z M262.5,114.9
            h0.3v0.3h-0.3V114.9z M254.2,114.9L254.2,114.9h0.6l0,0H254.2z M254.2,114.9c0-4.6,3.7-8.4,8.3-8.4v0.6c-4.2,0-7.7,3.5-7.7,7.8
            H254.2z M262.5,106.5v0.6V106.5z M262.8,106.8v8.1h-0.6v-8.1H262.8z M262.5,115.2h-8v-0.6h8V115.2z"/>
        </g>
      </g>
      <g id="room-08" onClick={() => room.set('room-08')}>
        <g transform="matrix(0.434474,0,0,1.95794,-22.6943,185.13)">
          <rect x="388.2" y="-35.4" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M604.1-35.4H388.2v84h215.9V-35.4z M601.3-34.7v82.8H391v-82.8H601.3z"/>
        </g>
        <g>
          <g transform="matrix(1,0,0,1,-0.761439,-6.2335)">
            <path className="st9" d="M194.8,204.5c-1.2,0-2.3-0.2-3.2-0.5c-0.9-0.4-1.6-0.9-2.1-1.6s-0.8-1.5-0.8-2.4s0.3-1.7,0.8-2.3
              c0.5-0.6,1.2-1.1,2.1-1.4c0.9-0.3,2-0.5,3.2-0.5c1.3,0,2.3,0.2,3.3,0.5c0.9,0.3,1.6,0.8,2.1,1.4s0.8,1.4,0.8,2.3
              s-0.3,1.7-0.8,2.4c-0.5,0.7-1.2,1.2-2.2,1.6C197.1,204.3,196,204.5,194.8,204.5z M194.8,202c0.8,0,1.5-0.2,2-0.6s0.8-0.9,0.8-1.5
              s-0.3-1.2-0.8-1.5c-0.5-0.4-1.2-0.6-2-0.6s-1.5,0.2-2,0.6s-0.7,0.9-0.7,1.5s0.2,1.2,0.7,1.5S194,202,194.8,202z M194.8,197.5
              c-1.1,0-2.1-0.2-3-0.5c-0.8-0.3-1.5-0.8-2-1.3c-0.5-0.6-0.7-1.3-0.7-2.1c0-0.9,0.2-1.6,0.7-2.2s1.1-1.1,2-1.4
              c0.8-0.3,1.8-0.5,2.9-0.5c1.1,0,2.1,0.2,2.9,0.5c0.8,0.3,1.5,0.8,2,1.4s0.7,1.3,0.7,2.2c0,0.8-0.2,1.5-0.7,2.1s-1.1,1-2,1.3
              S195.9,197.5,194.8,197.5z M194.8,195.5c0.7,0,1.3-0.2,1.7-0.5s0.6-0.8,0.6-1.3c0-0.6-0.2-1-0.6-1.4c-0.4-0.3-1-0.5-1.6-0.5
              c-0.7,0-1.2,0.2-1.6,0.5s-0.6,0.8-0.6,1.4s0.2,1,0.6,1.3C193.6,195.3,194.1,195.5,194.8,195.5z"/>
          </g>
          <rect x="153" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="153" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="159.7" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="159.7" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="166.5" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="166.5" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="173.2" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="173.2" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="180" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="180" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="202.1" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="202.1" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="208.8" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="208.8" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="215.5" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="215.5" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="222.3" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="222.3" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="229" y="125.5" className="st4" width="6.1" height="1.8"/>
          <rect x="229" y="127.9" className="st4" width="6.1" height="4.9"/>
          <rect x="153" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="153" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="159.7" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="159.7" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="166.5" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="166.5" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="173.2" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="173.2" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="180" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="180" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="202.1" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="202.1" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="208.8" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="208.8" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="215.5" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="215.5" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="222.3" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="222.3" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="229" y="139.6" className="st4" width="6.1" height="1.8"/>
          <rect x="229" y="142" className="st4" width="6.1" height="4.9"/>
          <rect x="153" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="153" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="159.7" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="159.7" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="166.5" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="166.5" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="173.2" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="173.2" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="180" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="180" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="202.1" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="202.1" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="208.8" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="208.8" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="215.5" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="215.5" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="222.3" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="222.3" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="229" y="153.7" className="st4" width="6.1" height="1.8"/>
          <rect x="229" y="156.1" className="st4" width="6.1" height="4.9"/>
          <rect x="153" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="153" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="159.7" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="159.7" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="166.5" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="166.5" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="173.2" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="173.2" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="180" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="180" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="202.1" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="202.1" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="208.8" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="208.8" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="215.5" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="215.5" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="222.3" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="222.3" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="229" y="167.8" className="st4" width="6.1" height="1.8"/>
          <rect x="229" y="170.2" className="st4" width="6.1" height="4.9"/>
          <rect x="153" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="153" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="159.7" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="159.7" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="166.5" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="166.5" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="173.2" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="173.2" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="180" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="180" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="202.1" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="202.1" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="208.8" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="208.8" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="215.5" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="215.5" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="222.3" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="222.3" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="229" y="181.9" className="st4" width="6.1" height="1.8"/>
          <rect x="229" y="184.3" className="st4" width="6.1" height="4.9"/>
          <rect x="153" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="153" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="159.7" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="159.7" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="166.5" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="166.5" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="173.2" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="173.2" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="180" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="180" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="202.1" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="202.1" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="208.8" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="208.8" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="215.5" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="215.5" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="222.3" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="222.3" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="229" y="196" className="st4" width="6.1" height="1.8"/>
          <rect x="229" y="198.4" className="st4" width="6.1" height="4.9"/>
          <rect x="153" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="153" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="159.7" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="159.7" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="166.5" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="166.5" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="173.2" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="173.2" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="180" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="180" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="202.1" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="202.1" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="208.8" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="208.8" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="215.5" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="215.5" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="222.3" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="222.3" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="229" y="210.1" className="st4" width="6.1" height="1.8"/>
          <rect x="229" y="212.6" className="st4" width="6.1" height="4.9"/>
          <rect x="153" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="153" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="159.7" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="159.7" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="166.5" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="166.5" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="173.2" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="173.2" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="180" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="180" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="202.1" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="202.1" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="208.8" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="208.8" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="215.5" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="215.5" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="222.3" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="222.3" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="229" y="224.2" className="st4" width="6.1" height="1.8"/>
          <rect x="229" y="226.7" className="st4" width="6.1" height="4.9"/>
          <rect x="154.8" y="238.9" className="st4" width="78.5" height="33.1"/>
          <rect x="157.6" y="242" className="st4" width="73.1" height="30"/>
          <rect x="160.7" y="245.7" className="st4" width="66.7" height="26.4"/>
          <rect x="163.4" y="248.7" className="st4" width="61.3" height="23.3"/>
          <rect x="168.4" y="253" className="st4" width="20.6" height="8.6"/>
          <rect x="166.6" y="270.2" className="st4" width="55" height="3.1"/>
        </g>
        <g>
          <g transform="matrix(-1,7.65714e-16,-7.65714e-16,-1,725.5,381.502)">
            <rect x="503.1" y="264.5" className="st2" width="7.7" height="2.1"/>
          </g>
          <path className="st3" d="M222.6,114.9v0.3h0.3v-0.3H222.6z M214.6,106.8v-0.3V106.8z M214.6,106.8v-0.3h-0.3v0.3H214.6z M214.6,114.9
            h-0.3v0.3h0.3V114.9z M222.9,114.9L222.9,114.9h-0.6l0,0H222.9z M222.9,114.9c0-4.6-3.7-8.4-8.3-8.4v0.6c4.2,0,7.7,3.5,7.7,7.8
            H222.9z M214.6,106.5v0.6V106.5z M214.3,106.8v8.1h0.6v-8.1H214.3z M214.6,115.2h8v-0.6h-8V115.2z"/>
          <g transform="matrix(1,8.74228e-08,8.74228e-08,-1,363,190.501)">
            <polygon className="st2" points="-140.2,75.6 -132.5,75.6 -132.5,75.6 			"/>
          </g>
          <path className="st3" d="M222.6,114.9v0.3h-0.3v-0.3H222.6z M230.6,106.8v0.3V106.8z M230.6,106.8v-0.3h0.3v0.3H230.6z M230.6,114.9
            h0.3v0.3h-0.3V114.9z M222.3,114.9L222.3,114.9h0.6l0,0H222.3z M222.3,114.9c0-4.6,3.7-8.4,8.3-8.4v0.6c-4.2,0-7.7,3.5-7.7,7.8
            H222.3z M230.6,106.5v0.6V106.5z M230.9,106.8v8.1h-0.6v-8.1H230.9z M230.6,115.2h-8v-0.6h8V115.2z"/>
        </g>
      </g>
      <g id="room-09" onClick={() => room.set('room-09')}>
        <g transform="matrix(0.675955,0,0,2.84617,-405.634,183.354)">
          <rect x="600.1" y="-23.6" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M816-23.6H600.1v84H816V-23.6z M814.2-23.2V60c0,0-191.6,0-212.3,0v-83.2H814.2L814.2-23.2z"/>
        </g>
        <g>
          <g transform="matrix(1,0,0,1,8.9415,1.49785)">
            <path className="st9" d="M63.2,120.2c1.3,0,2.5,0.3,3.5,0.8s1.7,1.4,2.3,2.4c0.5,1.1,0.8,2.4,0.8,4c0,1.7-0.3,3.1-1,4.3
              c-0.6,1.2-1.5,2.1-2.6,2.7s-2.4,0.9-3.9,0.9c-0.8,0-1.5-0.1-2.2-0.3c-0.7-0.2-1.3-0.4-1.8-0.8l1.3-2.5c0.4,0.3,0.8,0.5,1.3,0.6
              s0.9,0.1,1.4,0.1c1.3,0,2.2-0.4,3-1.1c0.8-0.8,1.1-1.9,1.1-3.4c0-0.3,0-0.5,0-0.8s0-0.6-0.1-0.9l0.9,0.9c-0.2,0.5-0.6,1-1,1.4
              s-0.9,0.6-1.5,0.8c-0.6,0.2-1.2,0.3-1.9,0.3c-0.9,0-1.8-0.2-2.5-0.6c-0.8-0.4-1.4-0.9-1.8-1.6c-0.4-0.7-0.7-1.5-0.7-2.4
              c0-1,0.2-1.9,0.7-2.6s1.2-1.3,2-1.7C61.2,120.4,62.2,120.2,63.2,120.2z M63.4,122.7c-0.5,0-0.9,0.1-1.3,0.3
              c-0.4,0.2-0.6,0.4-0.9,0.8c-0.2,0.3-0.3,0.7-0.3,1.1c0,0.7,0.2,1.2,0.7,1.6s1.1,0.6,1.8,0.6c0.5,0,0.9-0.1,1.3-0.3
              c0.4-0.2,0.7-0.5,0.9-0.8c0.2-0.3,0.3-0.7,0.3-1.1s-0.1-0.8-0.3-1.1c-0.2-0.3-0.5-0.6-0.9-0.8C64.4,122.8,63.9,122.7,63.4,122.7z
              "/>
          </g>
          <rect x="19.9" y="303.3" className="st4" width="106.7" height="33.1"/>
          <rect x="23.6" y="306.4" className="st4" width="99.3" height="30"/>
          <rect x="27.9" y="310.1" className="st4" width="90.8" height="26.4"/>
          <rect x="31.6" y="313.1" className="st4" width="83.4" height="23.3"/>
          <rect x="38.3" y="317.4" className="st4" width="28.2" height="8.6"/>
          <rect x="35.9" y="334.6" className="st4" width="74.8" height="3.1"/>
          <rect x="29.7" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="141.4" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="143.9" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="155.5" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="158" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="169.6" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="172.1" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="183.7" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="186.2" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="197.8" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="200.3" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="211.9" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="214.4" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="226" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="228.5" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="240.1" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="242.6" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="254.3" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="256.7" className="st4" width="6.1" height="4.9"/>
          <rect x="29.7" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="29.7" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="36.5" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="36.5" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="43.2" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="43.2" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="50" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="50" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="56.7" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="56.7" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="63.5" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="63.5" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="70.2" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="70.2" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="77" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="77" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="83.7" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="83.7" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="90.5" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="90.5" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="97.2" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="97.2" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="103.9" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="103.9" y="274.5" className="st4" width="6.1" height="4.9"/>
          <rect x="110.7" y="272" className="st4" width="6.1" height="1.8"/>
          <rect x="110.7" y="274.5" className="st4" width="6.1" height="4.9"/>
        </g>
        <g>
          <g transform="matrix(-1,7.65714e-16,-7.65714e-16,-1,421.5,381.502)">
            <rect x="292.3" y="264.5" className="st2" width="7.7" height="2.1"/>
          </g>
          <path className="st3" d="M129.4,114.9v0.3h0.3v-0.3H129.4z M121.4,106.8v-0.3h-0.3v0.3H121.4z M121.4,114.9h-0.3v0.3h0.3V114.9z
            M129.7,114.9L129.7,114.9h-0.6l0,0H129.7z M129.7,114.9c0-4.6-3.7-8.4-8.3-8.4v0.6c4.2,0,7.7,3.5,7.7,7.8H129.7z M121.4,106.5
            v0.6V106.5z M121.1,106.8v8.1h0.6v-8.1H121.1z M121.4,115.2h8v-0.6h-8C121.4,114.6,121.4,115.2,121.4,115.2z"/>
          <g transform="matrix(1,8.74228e-08,8.74228e-08,-1,211,190.501)">
            <polygon className="st2" points="-81.5,75.6 -73.8,75.6 -73.8,75.6 			"/>
          </g>
          <path className="st3" d="M129.4,114.9v0.3h-0.3v-0.3H129.4z M137.4,106.8v-0.3h0.3v0.3H137.4z M137.4,114.9h0.3v0.3h-0.3V114.9z
            M129.1,114.9L129.1,114.9h0.6l0,0H129.1z M129.1,114.9c0-4.6,3.7-8.4,8.3-8.4v0.6c-4.2,0-7.7,3.5-7.7,7.8H129.1z M137.4,106.5
            v0.6V106.5z M137.7,106.8v8.1h-0.6v-8.1H137.7z M137.4,115.2h-8v-0.6h8V115.2z"/>
        </g>
        <g>
          <g transform="matrix(-1,7.65714e-16,-7.65714e-16,-1,77.5,381.502)">
            <rect x="53.7" y="264.5" className="st2" width="7.7" height="2.1"/>
          </g>
          <path className="st3" d="M23.9,114.9v0.3h0.3v-0.3H23.9z M15.9,106.8v-0.3V106.8z M15.9,106.8v-0.3h-0.3v0.3H15.9z M15.9,114.9h-0.3
            v0.3h0.3V114.9z M24.2,114.9L24.2,114.9h-0.6l0,0H24.2z M24.2,114.9c0-4.6-3.7-8.4-8.3-8.4v0.6c4.2,0,7.7,3.5,7.7,7.8H24.2z
            M15.9,106.5v0.6V106.5z M15.6,106.8v8.1h0.6v-8.1H15.6z M15.9,115.2h8v-0.6h-8V115.2z"/>
          <g transform="matrix(1,8.74228e-08,8.74228e-08,-1,39,190.501)">
            <polygon className="st2" points="-14.9,75.6 -7.2,75.6 -7.2,75.6 			"/>
          </g>
          <path className="st3" d="M23.9,114.9v0.3h-0.3v-0.3H23.9z M31.9,106.8v0.3V106.8z M31.9,106.8v-0.3h0.3v0.3H31.9z M31.9,114.9h0.3v0.3
            h-0.3C31.9,115.2,31.9,114.9,31.9,114.9z M23.6,114.9L23.6,114.9h0.6l0,0H23.6z M23.6,114.9c0-4.6,3.7-8.4,8.3-8.4v0.6
            c-4.2,0-7.7,3.5-7.7,7.8H23.6z M31.9,106.5v0.6V106.5z M32.2,106.8v8.1h-0.6v-8.1H32.2z M31.9,115.2h-8v-0.6h8V115.2z"/>
        </g>
      </g>
      <g id="room">
        <g transform="matrix(0.267767,0,0,0.62353,417.91,-1.24706)">
          <rect x="-235.7" y="2.1" className="st8" width="215.9" height="84"/>
          <path className="st1" d="M-19.7,2.1h-215.9v84h215.9C-19.7,86.1-19.7,2.1-19.7,2.1z M-24.3,4v80.1h-206.8V4H-24.3z"/>
        </g>
        <g transform="matrix(1,0,0,1,82.8737,-54.2581)">
          <g transform="matrix(-1,0,0,1,526.5,137)">
            <polygon className="st2" points="235.5,-29.6 243.2,-29.6 243.2,-29.6 			"/>
          </g>
          <path className="st3" d="M291.1,107.4v-0.3h0.3v0.3H291.1z M283.1,115.5v0.3h-0.3v-0.3H283.1z M283.1,107.4h-0.3v-0.3h0.3V107.4z
            M291.4,107.4L291.4,107.4h-0.6l0,0H291.4z M291.4,107.4c0,4.6-3.7,8.4-8.3,8.4v-0.6c4.2,0,7.7-3.5,7.7-7.8H291.4z M283.1,115.8
            v-0.6V115.8z M282.8,115.5v-8.1h0.6v8.1H282.8z M283.1,107.1h8v0.6h-8V107.1z"/>
        </g>
      </g>
      <g id="marker">
        <circle className="st6" cx="572.4" cy="289.8" r="3.4"/>
        <path className="st7" d="M572.4,304.2l-2.9-10.1h5.8L572.4,304.2z"/>
      </g>
    </svg>
    </>
  )
}
