import { Swiper, SwiperSlide } from "swiper/react";

import ProjectCard from "../components/ProjectCard";

import { projects } from "../data/data";

import "swiper/css";


export default function Projects() {
  return (
    <Swiper className="swiper"
      slidesPerView={"auto"}
      centeredSlides={true}
      spaceBetween={10}>
        {projects.map((project) => <SwiperSlide key={project.id}><ProjectCard project={project} /></SwiperSlide>)}
    </Swiper>
  )
}
