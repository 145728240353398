import { useState } from "react";
import { motion } from "framer-motion";

import { about } from "../data/data";

import Button from "../components/Button";

export default function About() {

  const [ menuOpen, setMenuOpen ] = useState(false)
  const [ currentId, setCurrentId ] = useState(0)
  

  const handleMenuOpen = (id) => {
    if (!menuOpen) {
      setMenuOpen(prevMenuOpen => !prevMenuOpen)
      setCurrentId(id)
    } else {
      menuOpen && currentId === id ? setMenuOpen(false) : setCurrentId(id)
    }
  }

  const lineWidth = {
    width: '50vw',
    transition: 'all .4s ease'
  }

  if(menuOpen) {
    lineWidth.width = '5vw'
  } else {
    lineWidth.width = '45vw'
  }

  const menuItem = [
    {
      text: 'Misja',
      id: 1
    },
    {
      text: 'O fundacji',
      id: 2
    },
    {
      text: 'Działalność',
      id: 3
    },
    {
      text: 'Fundator',
      id: 4
    },
  ]

  const variants = {
    initial: {
      opacity: 0,
      x: 400,
      transition: { 
        duration: 0.7
      }
    },
    animate1: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7
      }
    },
    animate2: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.3
      }
    },
    animate3: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.6
      }
    },
    animate4: {
      opacity: 1,
      x: 0,
      transition: { 
        duration: 0.7,
        delay: 0.9
      }
    }
  }

  return (
    <div className='about'>
      <div className="about-menu">
        <div className="about-item-top about-item-1">
          <div className="h-line-top"></div>
          <div className="v-line-top" style={lineWidth}></div>
          <div className={menuOpen && currentId === 0 ? "about-button-top about-active" : "about-button-top"} onClick={() => handleMenuOpen(0)}>
            <Button btnContent={menuItem[0]}/>
          </div>
        </div>
        <div className="about-item-top about-item-2">
          <div className="h-line-top"></div>
          <div className="v-line-top" style={lineWidth}></div>
          <div className={menuOpen && currentId === 1 ? "about-button-top about-active" : "about-button-top"} onClick={() => handleMenuOpen(1)}>
            <Button btnContent={menuItem[1]}/>
          </div>
        </div>
        <div className="about-icon">
          <img src="./assets/logo-fundacja.png" alt="logo-fundacja" />
        </div>
        <div className="about-item-bottom about-item-3">
          <div className="h-line-bottom"></div>
          <div className="v-line-bottom" style={lineWidth}></div>
          <div className={menuOpen && currentId === 2 ? "about-button-bottom about-active" : "about-button-bottom"} onClick={() => handleMenuOpen(2)}>
            <Button btnContent={menuItem[2]}/>
          </div>
        </div>
        <div className="about-item-bottom about-item-4">
          <div className="h-line-bottom"></div>
          <div className="v-line-bottom" style={lineWidth}></div>
          <div className={menuOpen && currentId === 3 ? "about-button-bottom about-active" : "about-button-bottom"} onClick={() => handleMenuOpen(3)}>
            <Button btnContent={menuItem[3]}/>
          </div>
        </div>
      </div>
      <div className="about-content">
          {menuOpen && <motion.div className="content-box content-card" key={about[currentId].id} initial='initial' animate='animate1' variants={variants}> 
            <motion.div key={about[currentId].id + 1 } className="content-desc" initial='initial' animate='animate4' variants={variants}>
              {about[currentId].content}
            </motion.div>
          </motion.div>}
      </div>
    </div>
  )
}
