import { useState, useEffect } from "react"

export default function DateTime() {
 
  const [ currentDate, setCurrentDate ] = useState(new Date())

  const weakDay = ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota']
  const months = ['stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca', 'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia']

  useEffect(() => {
    const interval = setInterval(() => {
     const newDate = new Date()
     setCurrentDate(newDate)
    }, 60000);
    return () => clearInterval(interval);
  }, [setCurrentDate]);

  return (
    <div className="date-time">
      <p>{weakDay[currentDate.getDay()]}, <strong>{currentDate.getDate()} {months[currentDate.getMonth()]}</strong> {currentDate.getFullYear()} r., godz. <strong>{currentDate.getHours()}:{currentDate.getMinutes() < 10 ? '0' : ''}{currentDate.getMinutes()}</strong></p>
    </div>
  )
}
