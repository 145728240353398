import GalleryModule from "../components/GalleryModule";

export default function Gallery() {

  const images = [];

  for (let i = 1; i < 13; i++ ) {
    images.push(`./assets/gallery/gal-${i}.jpg`);
  }

  return (
    <div className="gallery">
      <GalleryModule />
    </div>
  )
}
