import { Swiper, SwiperSlide } from "swiper/react";

import Heksagon from "../components/Heksagon";

import { history } from "../data/data";

import "swiper/css";

export default function Timeline({ move }) {

  return (
    <div className="timeline-wrapper">
      <Swiper className="swiper timeline"
        slidesPerView={5}
        centeredSlides={true}
        spaceBetween={50}
        onRealIndexChange={(element) => move(element.activeIndex)}>
          {history.map((entry) => <SwiperSlide key={entry.date}><div className="heksagon-history"><Heksagon content={entry.date} />
          </div></SwiperSlide>)}
      </Swiper>
      <div className="timeline-line-wrapper"><div className="timeline-line"></div></div>
    </div>
  )
}
