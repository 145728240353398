import { useState, useEffect } from "react";

import HistoryCard from "../components/HistoryCard";
import Timeline from "../components/Timeline";

import { history } from "../data/data";

export default function History() {
  const [ activeDate, setActiveDate ] = useState(0);
  const [ activeEntry, setActiveEntry ] = useState(history[0]);

  const handleChangeDate = (date) => {
    setActiveDate(date);
  }

  useEffect(() => {
    setActiveEntry(history[activeDate]);
  }, [activeDate])

  return (
    <div className="history">
      <div className="history-top-wrapper">
        <div className="history-top">
          <h1 className="history-title">Historia<br></br>Fundacji</h1>
          <HistoryCard entry={ activeEntry }/>
        </div>
      </div>
      <Timeline move={ handleChangeDate }/>
    </div>
  )
}
