import { useState, useEffect } from 'react';
import { AnimatePresence, motion, } from 'framer-motion';

export default function Slider({ slides }) {

  const [id, setId] = useState(0);

  const variants = {
    initial: {
      opacity: 0,
      transition: { 
        duration: 0.5
      }
    },
    animate: {
      opacity: 1,
      transition: { 
        duration: 0.5
      }
    },
    exit: {
      opacity: 0,
      transition: { 
        duration: 0.5
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (id >= slides.imgNumber) {
        setId(prevId => prevId - slides.imgNumber);
      } else {
        setId(prevId => prevId + 1);
      }
    }, 2000);
    return () => clearInterval(interval);
  }, [id, setId, slides.imgNumber]);

  return (
    <div className='slider-holder'>
       <AnimatePresence>
        <motion.div className="slider-image" 
          style={{backgroundImage: `url(${slides.path}/${slides.images[id]})`}} 
          key={id} 
          initial='initial' 
          animate='animate' 
          exit='exit' 
          variants={variants}>
        </motion.div>
      </AnimatePresence>
    </div>
   
  )
}
